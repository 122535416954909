import ContactBankInfo from "~/types/ContactBankInfo";
import ContactAddress from "~/types/ContactAddress";
import ContactBusinessInfo from "~/types/ContactBusinessInfo";
import ContactPerson from "~/types/ContactPerson";

export interface IContact {
  id: number;
  // bankAccount: ContactBankInfo;
  bankAccount: {
    accountName: string | null;
    accountNumber: string | null;
    bankName: string | null;
  };
  // businessInfo: ContactBusinessInfo;
  business: {
    registrationNumber: string | null;
  };
  // contactPersons: ContactPerson;
  contactPersons: [
    {
      name: string;
      phoneNumber: string | null;
    }
  ];
  // addressInfo: ContactAddress;
  addresses: [
    {
      streetAddress: string | null;
      city: string | null;
      state: string | null;
      country: string | null;
      postcode: string | null;
      isDefaultBilling: boolean | null;
      isDefaultShipping: boolean | null;
    }
  ];
  creditLimit: string;
  currencyCode: string;
  legalName?: string;
  name: string;
  payable: number;
  receivable: number;
  tax: {
    taxId: string | null;
  };
}

export const initialAddressField = {
  streetAddress: null,
  city: null,
  state: null,
  country: null,
  postcode: null,
  isDefaultBilling: null,
  isDefaultShipping: null,
};

export const initialPersonField = {
  name: null,
  phoneNumber: null,
};

export const initialContactField = {
  id: null,
  bankAccount: {
    accountName: null,
    accountNumber: null,
    bankName: null,
  },
  business: {
    registrationNumber: null,
  },
  addresses: [],
  contactPersons: [],
  legalName: null,
  name: null,
  tax: {
    taxId: null,
  },
};
