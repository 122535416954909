<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { CaretSortIcon, CheckIcon } from '@radix-icons/vue'

import { cn } from '~/lib/utils'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '~/Components/ui/avatar'
import { Button } from '~/Components/ui/button'

import { Dialog } from '~/Components/ui/dialog'
import { Command, CommandGroup, CommandItem, CommandList } from '~/Components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/Components/ui/popover'
import type { TAuth } from '~/types/Auth'
import { UserOrganizationSelect } from '~/types/serializers'
import { useModuleApiStore } from '~/stores/moduleApi'
import { usersOrganizations } from '~/api'
import { useAuthSesion } from '~/stores/authSession'

const auth = useAuthSesion()
const moduleApi = useModuleApiStore()

type TSelectItem = {
  label: string,
  value: number,
}

const organization = computed(() => (auth as TAuth).organization)
const organizations = computed(() => (auth as TAuth).organizations)
let teams: TSelectItem[] = organizations.value.map((obj: UserOrganizationSelect) => {
  return {
    label: obj.name,
    value: obj.id
  }
})
const groups = [
  {
    label: 'Organizations',
    teams: teams
  }
]

type Team = (typeof groups)[number]['teams'][number]

const open = ref(false)
const showNewTeamDialog = ref(false)
const selectedTeam = ref<Team | undefined>(groups[0].teams.find((team) => team.value === organization.value?.id))

watch(selectedTeam, async (val, _old) => {
  if (!val) return

  await moduleApi.gotoUrl(usersOrganizations.switch, { params: { id: val.value }, method: 'patch' })
})
</script>

<template>
  <Dialog v-model:open="showNewTeamDialog" v-if="selectedTeam">
    <Popover v-model:open="open">
      <PopoverTrigger as-child>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded="open"
          aria-label="Select an organization"
          :class="cn('w-[200px] justify-between', $attrs.class ?? '')"
        >
          <Avatar class="w-5 h-5 mr-2">
            <AvatarImage
              :src="`https://avatar.vercel.sh/${selectedTeam.value}.png`"
              :alt="selectedTeam.label"
            />
            <AvatarFallback>SC</AvatarFallback>
          </Avatar>
          {{ selectedTeam.label }}
          <CaretSortIcon class="w-4 h-4 ml-auto opacity-50 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent class="w-[200px] p-0">
        <Command>
          <CommandList>
            <CommandGroup v-for="group in groups" :key="group.label" :heading="group.label">
              <CommandItem
                v-for="team in group.teams"
                :key="team.value"
                :value="team"
                class="text-sm"
                @select="() => {
                  selectedTeam = team
                  open = false
                }"
              >
                <Avatar class="w-5 h-5 mr-2">
                  <AvatarImage
                    :src="`https://avatar.vercel.sh/${team.value}.png`"
                    :alt="team.label"
                    class="grayscale"
                  />
                  <AvatarFallback>SC</AvatarFallback>
                </Avatar>
                {{ team.label }}
                <CheckIcon
                  :class="cn('ml-auto h-4 w-4',
                             selectedTeam.value === team.value
                               ? 'opacity-100'
                               : 'opacity-0',
                  )"
                />
              </CommandItem>
            </CommandGroup>
          </CommandList>
          <CommandList>
            <CommandItem class="p-3 border-t rounded-none" value="create-company" @select="() => open = false">
              <div class="flex">
                <slot />
              </div>
            </CommandItem>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  </Dialog>
  <div v-else>
    No selectable team
  </div>
</template>
