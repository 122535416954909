<script setup lang="ts">
import PageHeading from '~/Components/v8/PageHeading.vue';
import DataTable from './components/DataTable.vue';
import PagyMetadata from '~/types/Metadata';
import { columns } from './components/columns';
import { TQuotation } from '~/pages/Organizations/Quotations/types';
import { usePage } from '@inertiajs/vue3';
import { organizationsQuotations } from '~/api';

defineProps<{ quotations: TQuotation[], metadata: PagyMetadata }>()
const moduleApi = useModuleApiStore()
moduleApi.setVersion(usePage().version)
moduleApi.setNew(organizationsQuotations.new)
moduleApi.setShow(organizationsQuotations.show)
moduleApi.setEdit(organizationsQuotations.edit)
moduleApi.setDestroy(organizationsQuotations.destroy)

provide('action-show', true)
</script>

<template>
  <div class="flex-col flex-1 h-full p-8 space-y-8 md:flex">
    <PageHeading pageId="quotations" />
    <DataTable :data="quotations" :columns="columns" />
  </div>
</template>
