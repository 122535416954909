<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { useI18n } from 'vue-i18n';
import { z } from 'zod';
import { organizationsItems } from '~/api';
import Button from '~/Components/ui/button/Button.vue';
import H5 from '~/Components/v8/Typography/H5.vue';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle
} from '~/Components/ui/card';
import {
  FormField,
  FormControl,
  FormItem,
  FormLabel
} from '~/Components/ui/form';
import Input from '~/Components/ui/input/Input.vue';
import Separator from '~/Components/ui/separator/Separator.vue';
import PageHeading from '~/Components/v8/PageHeading.vue';
import { useModuleApiStore } from '~/stores/moduleApi';
import { FieldArray, useForm } from 'vee-validate';
import { V8Icon } from '~/Components/icon';
import { back } from '~/lib/utils';
import { usePage } from '@inertiajs/vue3';
import { Checkbox } from '~/Components/ui/checkbox';
import { OrganizationsItemsShow } from '~/types/serializers';
import { computed } from 'vue';
import { isNil } from 'lodash';
import CenterWrap from '~/Components/Wrapper/CenterWrap.vue';
import { Textarea } from '~/Components/ui/textarea';

// sample component localization
const { t } = useI18n()

const props = defineProps<{
  item: OrganizationsItemsShow
}>()

const itemSchema = toTypedSchema(z.object(
  {
    name: z.string(),
    code: z.string().optional().nullable(),
    uoms: z.array(z.object({
      name: z.string().min(2),
      rate: z.number().or(z.string()),
      id: z.number().optional().nullable(),
      base: z.boolean().optional(),
      cost: z.number().or(z.string()).nullable(),
      price: z.number().or(z.string()).nullable()
    })),
    purchaseDescription: z.string().optional().nullable(),
    salesDescription: z.string().optional().nullable()
  }
))

const uomBlank = {
  uoms: props.item.uoms
}
const isEditMode = computed(() => !isNil(props.item.id))
const initialValues = isEditMode.value ? props.item : uomBlank

const form = useForm({
  validationSchema: itemSchema,
  initialValues: initialValues
})

const moduleApi = useModuleApiStore();
moduleApi.setVersion(usePage().version)
const apiRoute = moduleApi.initCollectionFor('organizationsItems', organizationsItems)

const onSubmit = form.handleSubmit((values: any) => {
  console.log('onSubmit', values)

  if (isEditMode.value) {
    apiRoute.fetchUpdate(values, { params: { id: props.item.id } })
  } else {
    apiRoute.fetchCreate({ item: values })
  }
})
</script>

<template>
  <CenterWrap>
    <PageHeading :pageId="isEditMode ? 'edit_item' : 'add_item'" class="mt-8 mb-5" backButton hideDescription />
    <Card>
      <form @submit="onSubmit">
        <CardHeader>
          <CardTitle>{{ t('item_details') }}</CardTitle>
        </CardHeader>

        <CardContent class="grid gap-6">
          <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <FormField v-slot="{ componentField }" name="name">
              <FormItem>
                <FormLabel>{{ $t('name') }} *</FormLabel>
                <FormControl>
                  <Input type="text" placeholder="Pencil Staedler" v-bind="componentField" />
                </FormControl>
              </FormItem>
            </FormField>
            <FormField v-slot="{ componentField }" name="code">
              <FormItem>
                <FormLabel>{{ $t('code') }}</FormLabel>
                <FormControl>
                  <Input type="text" placeholder="ISBN-12345" v-bind="componentField" />
                </FormControl>
              </FormItem>
            </FormField>
          </div>
          <FormField v-slot="{ componentField }" name="purchaseDescription">
            <FormItem>
              <FormLabel>{{ $t('purchase_description') }}</FormLabel>
              <FormControl>
                <Textarea type="text" placeholder="" v-bind="componentField" />
              </FormControl>
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="salesDescription">
            <FormItem>
              <FormLabel>{{ $t('sales_description') }}</FormLabel>
              <FormControl>
                <Textarea type="text" placeholder="" v-bind="componentField" />
              </FormControl>
            </FormItem>
          </FormField>
          <Separator />
          <H5>{{ t('unit_of_measurements') }} *</H5>
          <div>
            <div class="flex">
              <div class="px-5 py-3 mb-5 text-sm rounded-tl-md items-top bg-secondary text-secondary-foreground">Base
              </div>
              <div
                class="flex justify-between w-full gap-20 py-3 mb-5 mr-10 text-sm rounded-tl-none rounded-t-md items-top bg-secondary text-secondary-foreground">
                <div class="w-1/4 px-5">Label</div>
                <div class="w-1/4 px-5">Rate</div>
                <div class="w-1/4 px-5">Cost</div>
                <div class="w-1/4 px-5">Price</div>
              </div>
            </div>
            <FieldArray v-slot="{ fields, push, remove }" name="uoms">
              <div v-for="(field, index) in fields" :key="`items-${field.key}`">
                <div class="flex">
                  <FormField v-slot="{ handleChange, value }" type="checkbox" :name="`uoms[${index}].base`">
                    <FormItem class="flex self-center px-5 pb-5">
                      <FormControl>
                        <Checkbox :checked="value" @update:checked="handleChange" />
                      </FormControl>
                    </FormItem>
                  </FormField>
                  <div class="flex justify-between w-full pb-5 items-top">
                    <FormField v-slot="{ componentField }" :name="`uoms[${index}].id`">
                      <FormItem>
                        <FormControl>
                          <Input type="hidden" v-bind="componentField" variant="naked" />
                        </FormControl>
                      </FormItem>
                    </FormField>
                    <FormField v-slot="{ componentField }" :name="`uoms[${index}].name`">
                      <FormItem>
                        <FormControl>
                          <Input type="text" v-bind="componentField" variant="naked" />
                        </FormControl>
                      </FormItem>
                    </FormField>
                    <FormField v-slot="{ componentField }" :name="`uoms[${index}].rate`">
                      <FormItem>
                        <FormControl>
                          <Input type="number" v-bind="componentField" variant="naked" />
                        </FormControl>
                      </FormItem>
                    </FormField>
                    <FormField v-slot="{ componentField }" :name="`uoms[${index}].cost`">
                      <FormItem>
                        <FormControl>
                          <Input type="number" v-bind="componentField" variant="naked" />
                        </FormControl>
                      </FormItem>
                    </FormField>
                    <FormField v-slot="{ componentField }" :name="`uoms[${index}].price`">
                      <FormItem class="relative pr-10">
                        <FormControl>
                          <Input type="number" v-bind="componentField" variant="naked" />
                        </FormControl>
                        <button type="button" class="absolute top-0 py-1 pl-2 pe-3 end-0 text-muted-foreground"
                          @click="remove(index)">
                          <V8Icon icon="lucide:x" class="w-4 h-4" />
                        </button>
                      </FormItem>
                    </FormField>
                  </div>
                </div>
              </div>
              <Button type="button" variant="outline" size="sm" class="w-20 mt-2 text-xs"
                @click="push(uomBlank.uoms[0])">
                {{ $t('add_uom') }}
              </Button>
            </FieldArray>
          </div>
        </CardContent>

        <CardFooter class="justify-end space-x-2">
          <Button type="button" @click="back" variant="outline">{{ $t('cancel') }}</Button>
          <Button type="submit">{{ $t('save_changes') }}</Button>
        </CardFooter>
      </form>
    </Card>
  </CenterWrap>
</template>


<!--
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '~/Components/ui/select';

<div class="grid gap-4 sm:grid-cols-2">
  <div class="grid gap-2">
    <Label :for="`area-${id}`">Area</Label>
    <Select defaultValue="billing">
      <SelectTrigger :id="`area-${id}`" aria-label="Area">
        <SelectValue placeholder="Select" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="team">Team</SelectItem>
        <SelectItem value="billing">Billing</SelectItem>
        <SelectItem value="account">Account</SelectItem>
        <SelectItem value="deployments">Deployments</SelectItem>
        <SelectItem value="support">Support</SelectItem>
      </SelectContent>
    </Select>
  </div>
</div>
-->

<i18n lang="yaml">
en:
  new_item: 'New Item'
</i18n>
