import { z } from "zod";
import {
  quotationSchema as saleOrderZodSchema,
} from "../../SaleOrders/types";

export type {
  TProductItem,
  TProductItemRow
} from "../../SaleOrders/types";

export type { default as TCreditNoteBase } from "~/types/serializers/Organizations/CreditNotes/Index";

// TODO: Currently make saleOrder as schema reference. Deprecating invoice
export const creditNoteSchema = saleOrderZodSchema.extend({});
export type TCreditNote = z.infer<typeof creditNoteSchema>;
