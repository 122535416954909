<script setup lang="ts">
import { useForm } from '@inertiajs/vue3'
import { FwbModal } from 'flowbite-vue'
import api from '~/api'

const emit = defineEmits(['close'])


const createUserForm = useForm({
  user: {
    email: null,
    // first_name: null,
    // last_name: null,
  }
})

function submit() {
  createUserForm.post(api.adminsUsers.create.path(), {
    onStart: (visit) => {
      console.log(visit)
    },
    onSuccess: (page) => {
      console.log(page)
      setTimeout(() => {
        emit('close')
      }, 200);

    },
    onError:(err) => {
      console.error(err)
    }
  })
}

function closeModal() {
  emit('close')
}
</script>

<template>
  <fwb-modal @close="closeModal" @click:outside="closeModal" class="jod">
    <template #header>
      <div class="flex items-center text-lg">
        Add new user
      </div>
    </template>
    <template #body>
      <form class="space-y-4">
        <div>
          <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
          <input type="email" name="email" id="email" v-model="createUserForm.user.email"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="name@company.com" required />
        </div>
      </form>
    </template>
    <template #footer>
      <div class="flex justify-end">
        <button type="submit" @click="submit" :disabled="createUserForm.processing"
          class=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Add
          User</button>
      </div>
    </template>
  </fwb-modal>
  <span class="self-center hidden justify-self-center md:h-full"></span>
  <!-- hack to compile the class use by FlowBite component -->
</template>

<!-- justify-self: center;
align-self: center; -->
