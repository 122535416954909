<script setup lang="ts">
import { createReusableTemplate, tryOnMounted, useVModel } from '@vueuse/core';
import { omit } from 'lodash';
import { ref } from 'vue';
import { usePage } from '@inertiajs/vue3';

import { Badge } from '~/Components/ui/badge'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '~/Components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '~/Components/ui/popover';

import { TProductItem } from '~/pages/Organizations/Quotations/types';
import Input from './Input.vue';

import { organizationsItems } from '~/api';
import { OrganizationsItemsShow } from '~/types/serializers';
import { TItemList } from '~/types/Generic';
import { useModuleApiStore } from '~/stores/moduleApi';

const moduleApi = useModuleApiStore()
moduleApi.setVersion(usePage().version)
moduleApi.setIndex(organizationsItems.index, { accept: 'json' })
moduleApi.setShow(organizationsItems.show, { accept: 'json' })

defineOptions({ inheritAttrs: false })

const props = defineProps < {
  defaultValue?: string | number
  modelValue?: string | number
  item?: TProductItem | OrganizationsItemsShow
}>()

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number): void
  (e: 'update:item', payload: TProductItem): void
}>()

const modelValue = useVModel(props, 'modelValue', emits, { passive: true })
const item = useVModel(props, 'item', emits)

const items = ref<TItemList[]>()
const [UseTemplate, StatusList] = createReusableTemplate()
const isOpen = ref(false)
const selectedItem = ref<TItemList | null>(null)

tryOnMounted(async () => {
  const response = await moduleApi.gotoIndex()
  items.value = response.items
})

async function onItemSelect(pickedItem: TItemList) {
  const response = await moduleApi.gotoShow(pickedItem.id)
  const itemDetails: OrganizationsItemsShow = response.props.item

  const itemForForm: TProductItem = {
    uomName: itemDetails.uoms[0].name,
    uomPrice: Number(itemDetails.uoms[0].price),
    uomCost: Number(itemDetails.uoms[0].cost),
    uomId: itemDetails.uoms[0].id,
    itemId: itemDetails.id,
    accountId: 0,
    discountAmount: 0,
    description: null,
    ...omit(pickedItem, ['id']),
  }

  selectedItem.value = pickedItem
  modelValue.value = pickedItem.name
  item.value = itemForForm
  isOpen.value = false
}
</script>

<template>
  <Popover v-model:open="isOpen">
    <PopoverTrigger as-child>
      <Input type="text" placeholder="Select contact" variant="naked" v-model="modelValue" />
    </PopoverTrigger>
    <PopoverContent class="w-[200px] p-0" align="start">
      <StatusList />
    </PopoverContent>
  </Popover>

  <UseTemplate>
    <Command>
      <CommandInput placeholder="Filter status..." />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          <CommandItem v-for="item of items" :key="item.id" :value="item.code || ''" @select="onItemSelect(item)"
            v-if="items">
            <div class="w-full">
              <Badge variant="outline" class="text-[10px] py-0 px-1 mr-1 rounded">{{ item.code }}</Badge>
              {{ item.name }}
            </div>
          </CommandItem>
        </CommandGroup>
      </CommandList>
    </Command>
  </UseTemplate>
</template>
