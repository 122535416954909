<script setup lang="ts">
import { computed, inject, useAttrs, type HTMLAttributes } from 'vue'
import { useVModel } from '@vueuse/core'
import { cn } from '~/lib/utils'
import { FormContextKey } from 'vee-validate';

defineOptions({
  inheritAttrs: false
})

const inputVariants = {
  'default': 'rounded-md border border-input bg-background ring-offset-background placeholder:text-muted-foreground focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:border-black ',
  'naked': "bg-transparent border-b focus-visible:border-black dark:focus-visible:border-white",
  'invisible': 'bg-transparent'
}

type InputVariants = typeof inputVariants

const props = withDefaults(defineProps<{
  defaultValue?: string | number
  modelValue?: string | number
  class?: HTMLAttributes['class'],
  variant?: keyof InputVariants
}>(), {
  variant: 'default'
})

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number): void
}>()

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue,
})

const attrs = useAttrs()
const form = inject(FormContextKey, undefined);
const message = computed<any>(() => {
  return form?.errors.value[attrs.name as any];
});

</script>

<template>
  <input
    v-model="modelValue"
    :class="cn(
        'flex h-10 w-full px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
        inputVariants[variant],
        props.class,
        (message && 'border-destructive')
      )"
    v-bind="$attrs"
  >
</template>
