import { z } from "zod";

export const addressSchema = z.object({
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  postcode: z.string().optional().nullable(),
  state: z.string().optional().nullable(),
  streetAddress: z.string().optional().nullable(),
});

export const itemSchema = z
  .object({
    id: z.number().optional().nullable(),
    price: z.number().or(z.string()).optional().nullable(), // server return string so add Union to string
    taxId: z.number().optional().nullable(),
    taxAmount: z.string().optional().nullable(),
    name: z.string().min(2),
    description: z.string().optional().nullable(),
    quantity: z.number(),
    uomName: z.string().optional(),
    uomPrice: z.number().optional(),
    uomCost: z.number().optional(),
    uomId: z.number().optional().nullable(),
    itemId: z.number().optional().nullable(),
    discountAmount: z.number().or(z.string()),
    accountId: z.number(),
    account: z
      .object({
        id: z.number(),
        code: z.string(),
        name: z.string(),
      })
      .optional()
      .nullable(),
    // taxPercentage: z.string()
  })
  .nullable();

export type TProductItem = z.infer<typeof itemSchema>;

export const quotationSchema = z.object({
  id: z.number().optional().nullable(),
  editable: z.boolean(),

  amount: z.string(),
  billingAddress: addressSchema.optional().nullable(),
  currencyCode: z.string(),
  customerId: z.number(),
  customer: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional()
    .nullable(),
  dueDate: z.string(),
  issueDate: z.string(),
  items: z.array(itemSchema).optional().nullable(),
  label: z.string(),
  notes: z.string().optional().nullable(),
  number: z.string(),
  reference: z.string().optional().nullable(),
  shippingAddress: addressSchema.optional().nullable(),
  status: z.string(),
  taxType: z.string(),

  // for show only (extracted from show serializer)
  // amount: z.string().optional(),
  // payableAmount: z.number().or(z.string()).optional(), // server sent as string
  // subTotalAmount: z.number().or(z.string()).optional(), // server sent as string
  // totalDiscountAmount: z.number().or(z.string()).optional(), // server sent as string
  // totalTaxAmount: z.number().or(z.string()).optional(), // server sent as string
  //- exists in edit mode

  // customerId: z.number(),
});

export type TQuotation = z.infer<typeof quotationSchema>;

export type TProductItemRow = {
  rowIndex: number;
  item: TProductItem;
};
