<script setup lang="ts">
import { h, ref, watch } from 'vue'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'

import { Button } from '~/Components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/Components/ui/form'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '~/Components/ui/dialog'
import { Input } from '~/Components/ui/input'
import { toast } from '~/Components/ui/toast'
import { useModuleApiStore } from '~/stores/moduleApi'
import { FieldArray } from 'vee-validate'
import { V8Icon } from '~/Components/icon'
import Separator from '~/Components/ui/separator/Separator.vue'
import { back } from '~/lib/utils'

const props = defineProps<{ dialogOpen: boolean }>()
const emit = defineEmits<{
  (e: 'toggle', value: boolean): void
}>()
const open = ref(props.dialogOpen)

watch(() => props.dialogOpen, (newState) => open.value = newState,
  { immediate: true }
)

const moduleApi = useModuleApiStore()

const formSchema = toTypedSchema(z.object({
  name: z.string().min(2).max(50),
  items: z.array(z.object({
    name: z.string().min(2).max(10),
    percentage: z.number()
  })),
}))

const onSubmit = (values: any) => {
  emit('toggle', open.value = false)

  if (moduleApi.currentEditRecord) {
    moduleApi.gotoUpdate(values)
  } else {
    moduleApi.gotoCreate({
      tax: values
    })
  }
}
</script>
<template>
  <Dialog v-bind:open="open" @update:open="(value) => $emit('toggle', value)">
    <DialogContent class="sm:max-w-[625px]">
      <DialogHeader>
        <DialogTitle>Add Tax Rates</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>

      <Form :validation-schema="formSchema" :initial-values="moduleApi.currentEditRecord" as="form" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="name">
          <FormItem>
            <FormLabel>Display Name</FormLabel>
            <FormControl>
              <Input type="text" placeholder="Exempt Sales" v-bind="componentField" />
            </FormControl>
            <FormDescription>
              Tax rate display name
            </FormDescription>
            <FormMessage />
          </FormItem>
        </FormField>

        <Separator class="my-5" />

        <div class="mt-5">
          <h5 class="mb-2 text-sm font-semibold tracking-tight scroll-m-20">Tax Components</h5>
          <FieldArray v-slot="{ fields, push, remove }" name="items">
            <div v-for="(field, index) in fields" :key="`items-${field.key}`">
              <div class="flex justify-between pb-5 items-top">
                <FormField v-slot="{ componentField }" :name="`items[${index}].name`">
                  <FormItem>
                    <FormControl>
                      <Input type="text" v-bind="componentField" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </FormField>

                <FormField v-slot="{ componentField }" :name="`items[${index}].percentage`">
                  <FormItem class="relative pr-10">
                    <FormControl>
                      <Input type="number" v-bind="componentField" />
                    </FormControl>
                    <FormMessage />
                    <button type="button" class="absolute top-0 py-1 pl-2 pe-3 end-0 text-muted-foreground"
                      @click="remove(index)">
                      <V8Icon icon="lucide:x" class="w-4 h-4" />
                    </button>
                  </FormItem>
                </FormField>
              </div>
            </div>
            <Button type="button" variant="outline" size="sm" class="w-20 mt-2 text-xs" @click="push({ value: '' })">
              Add Tax
            </Button>
          </FieldArray>
        </div>

        <DialogFooter class="mt-5">
          <Button type="button" @click="() => $emit('toggle', open = false)" variant="outline">{{ $t('cancel') }}</Button>
          <Button type="submit">{{ $t('save_changes') }}</Button>
        </DialogFooter>
      </Form>
    </DialogContent>
  </Dialog>
</template>
