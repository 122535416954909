import { getLocalTimeZone, parseAbsolute, toCalendarDate } from '@internationalized/date'
import type { Updater } from '@tanstack/vue-table'
import { type ClassValue, clsx } from 'clsx'
import { isEmpty, isNil, mapKeys, reject, snakeCase } from 'lodash'
import { twMerge } from 'tailwind-merge'
import type { Ref } from 'vue'
import { ItemSchema } from '~/pages/Organizations/Invoices/types/itemSchema'
import { TProductItem } from '~/pages/Organizations/SaleOrders/types'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function valueUpdater<T extends Updater<any>>(updaterOrValue: T, ref: Ref) {
  ref.value
    = typeof updaterOrValue === 'function'
      ? updaterOrValue(ref.value)
      : updaterOrValue
}

export const back = () => window.history.back();

export function getKeyByValue(object: any, value: string) {
  return Object.keys(object).find(key => object[key] === value);
}

export function convertKeysToSnakeCase(source: any) {
  return mapKeys(source, (_val, key) => snakeCase(key))
}

export function twSize(size: number): number {
  const rem = Math.floor(size / 16)
  return rem * 4
}

export function crash(message: string): never { throw new Error(message) }

export function dateFromDateTimeZone(source: string) {
  const date = parseAbsolute(source, getLocalTimeZone());
  return toCalendarDate(date);
}

/**
 * Fine tune rejection
 * 1. Allow for empty uomId and itemId BUT available name
 */
export function removeBlankItem(collection: any): ItemSchema[] | TProductItem[] {
  return reject(collection, (o: ItemSchema | TProductItem) => {
    if (isNil(o)) return false;

    return (o.itemId == 0 || isNil(o.itemId)) && isEmpty(o.name?.trim());
  });
}
