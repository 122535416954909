// JsFromRoutes CacheKey 5a065c59b6986fa914301d81dd2d2660
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/organization/members'),
  create: /* #__PURE__ */ definePathHelper('post', '/organization/members'),
  edit: /* #__PURE__ */ definePathHelper('get', '/organization/members/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/organization/members/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/organization/members/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/organization/members/:id'),
}
