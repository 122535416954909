<script setup lang="ts">
import DataTable from './components/DataTable.vue'
import { columns } from './components/columns'
import type { OrganizationsMembersIndex } from '~/types/serializers';
import PagyMetadata from '~/types/Metadata';
import MembersApi from '~/api/Organizations/MembersApi';
import { useModuleApiStore } from '~/stores/moduleApi';
import { usePage } from '@inertiajs/vue3';
import InviteMemberDialog from '~/pages/Organizations/Members/components/InviteMemberDialog.vue';
import { ref } from 'vue';

defineProps<{ members: OrganizationsMembersIndex[], metadata: PagyMetadata }>()
const dialogOpen = ref(false)
const openDialog = () => dialogOpen.value = true
const moduleApi = useModuleApiStore()
moduleApi.setVersion(usePage().version)
moduleApi.setNew(MembersApi.index, { sideAction: openDialog, accept: 'json', expectedKey: 'member' })
moduleApi.setEdit(MembersApi.show, { sideAction: openDialog, accept: 'json', expectedKey: 'member' })
</script>

<template>
  <div class="flex-col flex-1 h-full p-8 space-y-8 md:flex">
    <div class="flex items-center justify-between space-y-2">
      <div>
        <h2 class="text-2xl font-bold tracking-tight">
          {{ $t('members') }}
        </h2>
        <p class="text-muted-foreground">
          {{ $t('members_description') }}
        </p>
      </div>
    </div>
    <DataTable :data="members" :columns="columns" />
  </div>

  <InviteMemberDialog
    :dialogOpen="dialogOpen"
    @toggle="(open: boolean) => dialogOpen = open"
    :data="moduleApi.currentEditRecord"
  />
</template>
