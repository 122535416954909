<script setup lang="ts">
import { usePage } from '@inertiajs/vue3';
import Navbar from '~/Components/Navbar.vue'
import AdminSidebar from '~/Components/Navbar/AdminSidebar.vue'
import ContentWrapper from '~/Components/Wrapper/ContentWrapper.vue'
import { useAuthSesion } from '~/stores/authSession';

const page = usePage()
useAuthSesion().setSessionFromPage(page)
</script>

<template>
  <div class="bg-white dark:bg-background dark:text-foreground">
    <Navbar />
    <div class="flex min-h-[calc(100vh_-_theme(spacing.16))] overflow-hidden">
      <AdminSidebar />
      <ContentWrapper class="bg-primary-foreground">
        <slot></slot>
      </ContentWrapper>
    </div>
  </div>
</template>
