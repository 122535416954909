<script setup lang="ts">
import { Link } from '@inertiajs/vue3';
import { PathHelper } from '@js-from-routes/client';
import { startCase } from 'lodash';
import { ChevronLeft } from 'lucide-vue-next';
import Button from '~/Components/ui/button/Button.vue';
import { t, te } from '~/entrypoints/i18n';
import { back } from '~/lib/utils';

const props = withDefaults(defineProps<{
  pageId: string,
  hideDescription?: boolean,
  backButton?: boolean | PathHelper,
  editButtonPath?: string
}>(), {
  pageId: '0',
  hideDescription: false,
  backButton: false
})

function onClickBack() {
  if (typeof props.backButton === 'boolean') return back();

  useModuleApiStore().gotoUrl(props.backButton)
}
</script>

<template>
  <div class="flex items-center justify-between space-y-2">
    <div class="flex gap-4 grow">
      <Button variant="outline" size="icon" class="mt-[2px] h-7 w-7" @click="onClickBack" v-if="backButton">
        <ChevronLeft class="w-4 h-4" />
        <span class="sr-only">Back</span>
      </Button>
      <div>
        <h2 class="text-2xl font-bold tracking-tight">
          {{ startCase(t(pageId)) }}
        </h2>
        <p class="text-muted-foreground" v-if="!hideDescription && te(`${pageId}_description`)">
          {{ t(`${pageId}_description`) }}
        </p>
      </div>
    </div>
    <Link :href="editButtonPath" v-if="editButtonPath">
      <Button type="button" variant="outline">{{ t('action.button.edit') }}</Button>
    </Link>
  </div>
</template>
