<script setup lang="ts">
import DataTable from './components/DataTable.vue'
import { columns } from './components/columns'
import PageHeading from '~/Components/v8/PageHeading.vue';
import PagyMetadata from '~/types/Metadata';
import { organizationsInvoices } from '~/api';
import { usePage } from '@inertiajs/vue3';
import { useModuleApiStore } from '~/stores/moduleApi';
import { InvoiceSchema } from '~/pages/Organizations/Invoices/components/schema';
import { provide } from 'vue';

defineProps<{ invoices: InvoiceSchema[], metadata: PagyMetadata }>()
const moduleApi = useModuleApiStore()
moduleApi.setVersion(usePage().version)
moduleApi.setNew(organizationsInvoices.new)
moduleApi.setEdit(organizationsInvoices.edit)
moduleApi.setCreate(organizationsInvoices.create)
moduleApi.setShow(organizationsInvoices.show)
moduleApi.setDestroy(organizationsInvoices.destroy)

provide('action-show', true)
</script>

<template>
  <div class="flex-col flex-1 h-full p-8 space-y-8 md:flex">
    <PageHeading pageId="invoices" />
    <DataTable :data="invoices" :columns="columns" />
  </div>
</template>
