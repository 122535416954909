<script setup lang="ts">
import { Button } from '~/Components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '~/Components/ui/dialog'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel
} from '~/Components/ui/form'
import { Input } from '~/Components/ui/input'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { z } from 'zod'
import api from '~/api'
import { router } from '@inertiajs/vue3'
import { ref, watch } from 'vue'
import { OrganizationsMembersShow } from '~/types/serializers'

const schema = toTypedSchema(
  z.object({
    firstName: z.string().optional().nullable(),
    lastName: z.string().optional().nullable(),
    email: z.string()
  })
)
defineOptions({ inheritAttrs: false })
const props = defineProps<{
  dialogOpen: boolean,
  data?: OrganizationsMembersShow
}>()

const emits = defineEmits<{ (e: 'toggle', value: boolean): void }>()
const isOpen = ref(props.dialogOpen)

watch(() => props.dialogOpen, (value) => {
  props.data && form.setValues(props.data)
  isOpen.value = value
})

const form = useForm({
  validationSchema: schema
})

const onSubmit = form.handleSubmit((values) => {
  emits('toggle', isOpen.value = false)
  router.post(api.organizationsMembers.create.path(), { user: values }, {
    onError: (err: any) => {
      console.log('err', err)
    }
  })
})
</script>

<template>
  <Dialog v-bind:open="isOpen" @update:open="(value) => $emit('toggle', value)">
    <DialogContent class="sm:max-w-[425px]">
      <form @submit="onSubmit">
        <DialogHeader>
          <DialogTitle>{{ $t('organization.dialog.invite_member') }}</DialogTitle>
          <DialogDescription>
            {{ $t('organization.dialog.invite_member_description') }}
          </DialogDescription>
        </DialogHeader>
        <div class="grid gap-4 py-4">
          <FormField v-slot="{ componentField }" name="email">
            <FormItem class="grid items-center grid-cols-4 gap-4">
              <FormLabel class="text-right">{{ $t('email') }} *</FormLabel>
              <FormControl>
                <Input v-bind="componentField" class="col-span-3" />
              </FormControl>
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="firstName">
            <FormItem class="grid items-center grid-cols-4 gap-4">
              <FormLabel class="text-right">{{ $t('first_name') }}</FormLabel>
              <FormControl>
                <Input v-bind="componentField" class="col-span-3" />
              </FormControl>
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="lastName">
            <FormItem class="grid items-center grid-cols-4 gap-4">
              <FormLabel class="text-right">{{ $t('last_name') }}</FormLabel>
              <FormControl>
                <Input v-bind="componentField" class="col-span-3" />
              </FormControl>
            </FormItem>
          </FormField>
        </div>
        <DialogFooter class="mt-5">
          <Button type="submit">
            {{ $t('organization.dialog.button.invite') }}
          </Button>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
</template>
