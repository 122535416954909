<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { usePage } from '@inertiajs/vue3'
import { AlertCircle } from 'lucide-vue-next'
import { Alert, AlertTitle, AlertDescription } from '~/Components/ui/alert'
import { InnerWrap } from "~/Components/Wrapper";
import InfoAlert from '~/Components/InfoAlert.vue'
import Sidebar from '~/Components/Sidebar.vue'
import Navbar from '~/Components/Navbar.vue'
import ContentWrapper from '~/Components/Wrapper/ContentWrapper.vue'
import Toaster from '~/Components/ui/toast/Toaster.vue'
import { useSubscribeInertiaEvent } from '~/composables/useSubscribeInertiaEvent'


defineProps({ errors: Object })

const isShowInfo = ref(false)
const page = usePage()
const flash = computed(() => (page.props.flash as any))
useSubscribeInertiaEvent()

watch(
  () => flash.value,
  (fl) => {
    if (fl?.info) {
      isShowInfo.value = true
      console.log(`Flash is: ${fl.info}`)
      setTimeout(() => isShowInfo.value = false, 5000)
    }
  }
)

</script>

<template>
  <div class="bg-white dark:bg-background dark:text-foreground">
    <Navbar />
    <div class="flex min-h-[calc(100vh_-_theme(spacing.16))] overflow-hidden">
      <Sidebar />
      <ContentWrapper class="bg-primary-foreground">
        <InnerWrap v-if="isShowInfo">
          <InfoAlert :message="flash.info" @close="isShowInfo = false"/>
        </InnerWrap>

        <InnerWrap v-if="errors">
          <Alert variant="destructive">
            <AlertCircle class="w-4 h-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription v-for="message in errors">
              {{ message }}
            </AlertDescription>
          </Alert>
        </InnerWrap>

        <slot></slot>
      </ContentWrapper>
    </div>
  </div>
  <Toaster />
</template>
