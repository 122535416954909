// JsFromRoutes CacheKey 7ef96e5bc757b32b343db9f951a90293
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  void: /* #__PURE__ */ definePathHelper('patch', '/organization/sale_orders/:id/void'),
  submit: /* #__PURE__ */ definePathHelper('patch', '/organization/sale_orders/:id/submit'),
  approve: /* #__PURE__ */ definePathHelper('patch', '/organization/sale_orders/:id/approve'),
  index: /* #__PURE__ */ definePathHelper('get', '/organization/sale_orders'),
  create: /* #__PURE__ */ definePathHelper('post', '/organization/sale_orders'),
  new: /* #__PURE__ */ definePathHelper('get', '/organization/sale_orders/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/organization/sale_orders/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/organization/sale_orders/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/organization/sale_orders/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/organization/sale_orders/:id'),
}
