// JsFromRoutes CacheKey 301c4ac847af2a7db87ec5786472b45e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  void: /* #__PURE__ */ definePathHelper('patch', '/organization/delivery_orders/:id/void'),
  approve: /* #__PURE__ */ definePathHelper('patch', '/organization/delivery_orders/:id/approve'),
  index: /* #__PURE__ */ definePathHelper('get', '/organization/delivery_orders'),
  create: /* #__PURE__ */ definePathHelper('post', '/organization/delivery_orders'),
  new: /* #__PURE__ */ definePathHelper('get', '/organization/delivery_orders/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/organization/delivery_orders/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/organization/delivery_orders/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/organization/delivery_orders/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/organization/delivery_orders/:id'),
}
