<script setup lang="ts">
import DataTable from './components/DataTable.vue'
import { columns } from './components/columns'
import PageHeading from '~/Components/v8/PageHeading.vue';
import PagyMetadata from '~/types/Metadata';
import { OrganizationsItemsIndex } from '~/types/serializers';
import { useModuleApiStore } from '~/stores/moduleApi';
import { organizationsItems } from '~/api';
import { usePage } from '@inertiajs/vue3';

defineProps<{
  items: OrganizationsItemsIndex[],
  metadata: PagyMetadata
}>()

const moduleApi = useModuleApiStore()

moduleApi.setVersion(usePage().version)
moduleApi.setNew(organizationsItems.new)
moduleApi.setUpdate(organizationsItems.update)
moduleApi.setEdit(organizationsItems.edit)
moduleApi.setShow(organizationsItems.show)
moduleApi.setDestroy(organizationsItems.destroy)
</script>

<template>
  <div class="flex-col flex-1 h-full p-8 space-y-8 md:flex">
    <PageHeading pageId="product_services" />
    <DataTable :data="items" :columns="columns" />
  </div>
</template>
