// JsFromRoutes CacheKey 4324e7e233559830075abdac050e0cdb
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  void: /* #__PURE__ */ definePathHelper('patch', '/organization/invoices/:id/void'),
  approve: /* #__PURE__ */ definePathHelper('patch', '/organization/invoices/:id/approve'),
  index: /* #__PURE__ */ definePathHelper('get', '/organization/invoices'),
  create: /* #__PURE__ */ definePathHelper('post', '/organization/invoices'),
  new: /* #__PURE__ */ definePathHelper('get', '/organization/invoices/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/organization/invoices/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/organization/invoices/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/organization/invoices/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/organization/invoices/:id'),
}
