<script setup lang="ts">
import { computed, unref } from 'vue';

// form UI components
import { Input } from '~/Components/ui/input'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '~/Components/ui/form'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '~/Components/ui/card'

import PageHeading from '~/Components/v8/PageHeading.vue';
import * as z from 'zod'
import { toTypedSchema } from '@vee-validate/zod';
import Button from '~/Components/ui/button/Button.vue';
import Combobox from '~/Components/v8/ComboBox.vue';
import { back } from '~/lib/utils';
import { useModuleApiStore } from '~/stores/moduleApi';
import { isNil, map, merge, startCase } from 'lodash';
import { organizationsContacts } from '~/api';
import { FieldArray, useForm } from 'vee-validate';
import CenterWrap from '~/Components/Wrapper/CenterWrap.vue';
import Separator from '~/Components/ui/separator/Separator.vue';
import { IContact, initialAddressField, initialContactField, initialPersonField } from './types/contact';
import { PlusIcon } from 'lucide-vue-next';
import { Checkbox } from '~/Components/ui/checkbox';
import { V8Icon } from '~/Components/icon';
import { ref } from 'vue';

interface CurrencyCode {
  code: string,
  currency_code: string,
  flag: string,
  name: string
}

const props = defineProps<{
  contact: IContact,
  currency_codes: CurrencyCode[]
}>()
const hoveredOnAddress = ref(false)
const moduleApi = useModuleApiStore()
const apiRoute = moduleApi.initCollectionFor('organizationContacts', organizationsContacts)

moduleApi.setUpdate(organizationsContacts.update)
moduleApi.setShow(organizationsContacts.show)

const itemSchema = toTypedSchema(z.object(
  {
    id: z.number().optional().nullable(),
    name: z.string().optional().nullable(),
    legalName: z.string(),
    currencyCode: z.string().optional(),
    creditLimit: z.string().or(z.number()).optional().nullable(),
    business: z.object({
      registrationNumber: z.string().optional().nullable()
    }).optional().nullable(),
    addresses: z.array(z.object({
      streetAddress: z.string().optional().nullable(),
      city: z.string().optional().nullable(),
      state: z.string().optional().nullable(),
      country: z.string().optional().nullable(),
      postcode: z.string().or(z.number()).optional().nullable(),
      isDefaultShipping: z.boolean().optional().nullable(),
      isDefaultBilling: z.boolean().optional().nullable()
    })).optional().nullable(),
    tax: z.object({
      taxId: z.string().optional().nullable()
    }),
    bankAccount: z.object({
      accountName: z.string().optional().nullable(),
      accountNumber: z.string().optional().nullable(),
      bankName: z.string().optional().nullable(),
    }).optional().nullable(),
    contactPersons: z.array(z.object({
      name: z.string(),
      phoneNumber: z.string().optional().nullable(),
    })).optional().nullable()
  }
))

const isEditMode = computed(() => !isNil(props.contact.id))
const initialValues = isEditMode.value ? unref(props.contact) : merge({}, props.contact, initialContactField)

const form = useForm({
  validationSchema: itemSchema,
  initialValues: initialValues
})

const formConfig = {
  validateOnBlur: false,
  validateOnChange: false
}

const onSubmit = form.handleSubmit((values: any) => {
  if (isEditMode.value) {
    moduleApi.gotoUpdate({ id: initialValues.id, ...values }, initialValues.id)
  } else {
    console.log(values)
    // let v = omitBy(values, isNil)
    // console.log('v',v)
    apiRoute.fetchCreate({ contact: values })
  }
})

const comboBoxItems = map(props.currency_codes, (currency) => {
  return {
    value: currency.currency_code,
    label: `${currency.flag} ${currency.currency_code} ${currency.name}`
  }
})
</script>

<template>
  <CenterWrap>
    <form @submit="onSubmit">
      <div class="content-stretch">
        <PageHeading :pageId="isEditMode ? 'edit_contact' : 'add_contact'" class="my-8" backButton hideDescription />

        <Card>
          <CardHeader>
            <CardTitle>{{ $t('contact_details') }}</CardTitle>
          </CardHeader>
          <CardContent>
            <div class="grid grid-cols-2 gap-5 lg:grid-cols-2">
              <FormField v-slot="{ componentField }" name="legalName" v-bind="formConfig">
                <FormItem>
                  <FormLabel>{{ startCase($t('legal_name')) }} *</FormLabel>
                  <FormControl>
                    <Input type="text" placeholder="Michael Jordan" v-bind="componentField" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              </FormField>

              <FormField v-slot="{ componentField }" name="name">
                <FormItem>
                  <FormLabel>{{ startCase($t('name')) }} *</FormLabel>
                  <FormControl>
                    <Input type="text" placeholder="Alexander" v-bind="componentField" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              </FormField>

              <FormField v-slot="{ componentField }" name="business.registrationNumber">
                <FormItem>
                  <FormLabel>{{ startCase($t('business_registration_number')) }}</FormLabel>
                  <FormControl>
                    <Input type="text" placeholder="1312525-A" v-bind="componentField" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              </FormField>
            </div>

            <Separator class="my-8" />

            <div class="space-y-5">
              <h3>{{ startCase($t('contact_person', 2)) }}</h3>
              <FieldArray v-slot="{ fields, push, remove }" name="contactPersons">
                <div
                  class="grid grid-cols-2 gap-5"
                  v-for="(field, index) in fields"
                  :key="`contact-persons-${field.key}`"
                  v-if="fields.length"
                >
                  <FormField v-slot="{ componentField }" :name="`contactPersons[${index}].name`">
                    <FormItem>
                      <FormLabel>{{ startCase($t('name')) }} *</FormLabel>
                      <FormControl>
                        <Input type="text" placeholder="Lucious Erdman" v-bind="componentField" />
                      </FormControl>
                    </FormItem>
                  </FormField>

                  <FormField v-slot="{ componentField }" :name="`contactPersons[${index}].phoneNumber`">
                    <FormItem>
                      <FormLabel>{{ startCase($t('phone_number')) }}</FormLabel>
                      <div class="flex">
                        <FormControl>
                          <Input type="text" placeholder="177-059-0510" v-bind="componentField" />
                        </FormControl>
                        <Button
                          variant="ghost"
                          size="icon"
                          type="button"
                          class="mr-5 rounded-full hover:bg-transparent"
                          @click="remove(index)"
                          v-show="true"
                        >
                          <V8Icon icon="lucide:x" class="w-4 h-4" />
                        </Button>
                      </div>
                    </FormItem>
                  </FormField>
                </div>
                <template v-else>
                  <div class="col-span-3 p-5 text-center bg-slate-50 dark:bg-primary-foreground">{{ startCase($t('no_contact')) }}</div>
                </template>

                <Button
                  @click="push(initialPersonField)"
                  variant="ghost"
                  type="button"
                  class="w-full border-2 border-dashed cursor-pointer border-zinc-400 hover:border-zinc-500 group"
                >
                  <PlusIcon
                    strokeWidth="3"
                    class="mr-2 text-gray-400 group-hover:text-gray-600"
                  /><span class="text-lg">{{ startCase($t('contact')) }}</span>
                </Button>
              </FieldArray>
            </div>

            <Separator class="my-8" />

            <div class="space-y-5">
              <h3>{{ startCase($t('address', 2)) }}</h3>
              <FieldArray v-slot="{ fields, push, remove }" name="addresses">
                <div id="address-container" class="grid grid-cols-2 gap-5">
                  <div @mouseenter="hoveredOnAddress = true" @mouseleave="hoveredOnAddress = false"
                    class="relative p-5 bg-slate-50 dark:bg-primary-foreground" v-for="(field, index) in fields"
                    :key="`addresses-${field.key}`" v-if="fields.length">
                    <div class="space-y-5">
                      <FormField v-slot="{ componentField }" :name="`addresses[${index}].streetAddress`">
                        <FormItem>
                          <FormLabel>{{ startCase($t('street_address')) }}</FormLabel>
                          <FormControl>
                            <Input type="text" placeholder="3 Pasar Besar Awam Jln Garuda Larkin"
                              v-bind="componentField" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      </FormField>

                      <div class="grid grid-cols-2 gap-5">
                        <FormField v-slot="{ componentField }" :name="`addresses[${index}].postcode`">
                          <FormItem>
                            <FormLabel>{{ startCase($t('postcode')) }}</FormLabel>
                            <FormControl>
                              <Input type="number" placeholder="15150" v-bind="componentField" />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        </FormField>

                        <FormField v-slot="{ componentField }" :name="`addresses[${index}].city`">
                          <FormItem>
                            <FormLabel>{{ startCase($t('city')) }}</FormLabel>
                            <FormControl>
                              <Input type="text" placeholder="Georgetown" v-bind="componentField" />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        </FormField>

                        <FormField v-slot="{ componentField }" :name="`addresses[${index}].state`">
                          <FormItem>
                            <FormLabel>{{ startCase($t('state')) }}</FormLabel>
                            <FormControl>
                              <Input type="text" placeholder="Penang" v-bind="componentField" />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        </FormField>

                        <FormField v-slot="{ componentField }" :name="`addresses[${index}].country`">
                          <FormItem>
                            <FormLabel>{{ startCase($t('country')) }}</FormLabel>
                            <FormControl>
                              <Input type="text" placeholder="Malaysia" v-bind="componentField" />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        </FormField>

                        <FormField v-slot="{ value, handleChange }" type="checkbox"
                          :name="`addresses[${index}].isDefaultBilling`">
                          <FormItem class="flex flex-row items-start p-4 space-y-0 rounded-md gap-x-3">
                            <FormControl>
                              <Checkbox :checked="value" @update:checked="handleChange" />
                            </FormControl>
                            <div class="space-y-1 leading-none">
                              <FormLabel>Default billing</FormLabel>
                            </div>
                          </FormItem>
                        </FormField>

                        <FormField v-slot="{ value, handleChange }" type="checkbox"
                          :name="`addresses[${index}].isDefaultShipping`">
                          <FormItem class="flex flex-row items-start p-4 space-y-0 rounded-md gap-x-3">
                            <FormControl>
                              <Checkbox :checked="value" @update:checked="handleChange" />
                            </FormControl>
                            <div class="space-y-1 leading-none">
                              <FormLabel>Default shipping</FormLabel>
                            </div>
                          </FormItem>
                        </FormField>
                      </div>
                    </div>
                    <Button variant="ghost" size="icon" type="button"
                      class="absolute top-0 right-0 mr-5 rounded-full hover:bg-transparent" @click="remove(index)"
                      v-show="hoveredOnAddress">
                      <V8Icon icon="lucide:x" class="w-4 h-4" />
                    </Button>
                  </div>
                  <template v-else>
                    <div class="col-span-3 p-5 text-center bg-slate-50 dark:bg-primary-foreground">{{ startCase($t('no_address')) }}</div>
                  </template>
                </div>
                <Button @click="push(initialAddressField)" variant="ghost" type="button"
                  class="w-full border-2 border-dashed cursor-pointer border-zinc-400 hover:border-zinc-500 group">
                  <PlusIcon strokeWidth="3" class="mr-2 text-gray-400 group-hover:text-gray-600" /><span
                    class="text-lg">{{ startCase($t('address')) }}</span>
                </Button>
              </FieldArray>
            </div>

            <Separator class="my-8" />

            <div class="space-y-5">
              <h3>{{ startCase($t('financial_details')) }}</h3>
              <div class="grid grid-cols-2 gap-5">
                <FormField v-slot="{ componentField }" name="tax.taxId">
                  <FormItem>
                    <FormLabel>{{ startCase($t('tax_id_number')) }}</FormLabel>
                    <FormControl>
                      <Input type="text" placeholder="IG4040080091" v-bind="componentField" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </FormField>

                <FormField v-slot="{ componentField }" name="currencyCode">
                  <FormItem>
                    <FormLabel>{{ startCase($t('currency_code')) }} *</FormLabel>
                    <FormControl>
                      <Combobox v-bind="componentField" :listItems="comboBoxItems" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </FormField>

                <FormField v-slot="{ componentField }" name="bankAccount.accountName">
                  <FormItem>
                    <FormLabel>{{ startCase($t('account_name')) }}</FormLabel>
                    <FormControl>
                      <Input type="text" placeholder="CMA CGM & ANL Securities B.V." v-bind="componentField" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </FormField>

                <FormField v-slot="{ componentField }" name="bankAccount.accountNumber">
                  <FormItem>
                    <FormLabel>{{ startCase($t('account_number')) }}</FormLabel>
                    <FormControl>
                      <Input type="text" placeholder="300-025004-101" v-bind="componentField" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </FormField>

                <FormField v-slot="{ componentField }" name="bankAccount.bankName">
                  <FormItem>
                    <FormLabel>{{ startCase($t('bank_name')) }}</FormLabel>
                    <FormControl>
                      <Input type="text" placeholder="HSBC BANK MALAYSIA BERHAD" v-bind="componentField" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </FormField>
              </div>
            </div>

            <Separator class="my-8" />

            <div class="space-y-5">
              <h3>{{ startCase($t('sales_defaults')) }}</h3>
              <div class="grid grid-cols-2">
                <FormField v-slot="{ componentField }" name="creditLimit" v-bind="formConfig">
                  <FormItem>
                    <FormLabel>{{ $t('credit_limit') }}</FormLabel>
                    <FormControl>
                      <Input type="number" placeholder="950" v-bind="componentField" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </FormField>
              </div>
            </div>
          </CardContent>

          <Separator class="my-8" />

          <CardFooter class="justify-end space-x-2">
            <Button type="button" @click="back" variant="outline">{{ $t('cancel') }}</Button>
            <Button type="submit">{{ $t('save_changes') }}</Button>
          </CardFooter>
        </Card>
      </div>
    </form>
  </CenterWrap>
</template>
