import { extend, forIn, isNil, last } from "lodash";
import { defineStore } from "pinia";
import { ref } from "vue";
import type { TInvoiceFormSchema } from '~/pages/Organizations/Invoices/components/formSchema'
import type { ItemSchema } from "~/pages/Organizations/Invoices/types/itemSchema";
import { ItemCreate } from "~/pages/Organizations/Invoices/types/Item";
import { OrganizationsTaxesIndex } from "~/types/serializers";

const defaultItem = {
  name: '',
  description: '',
  quantity: 0,
  uomPrice: 0,
  uomName: '',
  uomId: 0,
  itemId: 0,
  // taxId: 0,
  discountAmount: 0,
  accountId: 0
};

export const useInvoiceFormData = defineStore('invoiceFormData', () => {
  const invoiceFormData = ref<Partial<TInvoiceFormSchema>>();
  const fieldRemove = ref();
  const fieldPush = ref();
  const fieldInsert = ref();
  const fieldReplace = ref(); // field{action} store action from useFieldArray() form
  const resetForm = ref();
  const taxForItem = ref<{[key: string]: OrganizationsTaxesIndex }>();

  // actions
  function removeItem(itemIndex: number) {
    if (fieldRemove) {
      console.log("removing...", fieldRemove);
      fieldRemove.value(itemIndex);
    }
  }

  function pushItem(item?: ItemSchema) {
    if (!item) item = defaultItem as ItemSchema;

    fieldPush.value(item);
  }

  function updateItem(itemIndex: number, payload: ItemCreate) {
    const theItem = invoiceFormData.value?.items?.at(itemIndex);

    if (theItem) {
      // console.log("payload", payload);
      const newItem = extend({}, theItem, payload);
      // console.log("theItem", newItem);

      fieldRemove.value(itemIndex)
      fieldInsert.value(itemIndex, newItem)

      // if success, add 1 more row
      addBlankItem();
    }
  }

  function addBlankItem() {
    const lastItem = last(invoiceFormData.value?.items);

    if (!isNil(lastItem?.itemId) && lastItem?.itemId !== defaultItem.itemId)
      pushItem();
  }

  function initialItemState() {
    addBlankItem();
  }

  function updateTaxForItem(
    itemIndex: number,
    payload: OrganizationsTaxesIndex
  ) {
    // console.log('updateTaxForItem', taxForItem.value, payload)
    if (taxForItem.value) {
      const taxItem = forIn(taxForItem.value, (_o: OrganizationsTaxesIndex, keyIndex: string) => {
        return itemIndex === Number(keyIndex);
      });
      taxItem[itemIndex] = payload;
    } else {
      taxForItem.value = {
        [itemIndex]: payload
      }
    }
  }

  return {
    // state
    invoiceFormData,
    fieldRemove,
    fieldPush,
    fieldReplace,
    fieldInsert,
    resetForm,
    taxForItem,

    // method
    removeItem,
    pushItem,
    updateItem,
    updateTaxForItem,

    initialItemState,
  };
})
