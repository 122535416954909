import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { UserOrganizationSelect, UsersProfile } from "~/types/serializers";
import { Page, PageProps } from "@inertiajs/core";

// Ref: https://blog.logrocket.com/types-vs-interfaces-typescript/
export type TUser = UsersProfile & {
  name: string;
}

export const useAuthSesion = defineStore('authSession', () => {
  // states
  const user = ref<TUser>()
  const organization = ref<UserOrganizationSelect>()
  const organizations = ref<UserOrganizationSelect[]>() // available organizations to choose from

  // getters
  const isAuthed = computed(() => user.value?.id)
  const isUser = computed(() => isAuthed && !user.value?.isAdmin)
  const isAdmin = computed(() => isAuthed && user.value?.isAdmin)

  // methods
  function setSessionFromPage(page: Page<PageProps>) {

    if (page.props.auth) {
      const auth = page.props.auth as PageProps;
      setUser(auth.user as TUser);
      setOrganization(auth.organization as UserOrganizationSelect);
      setOrganizations(auth.organizations as UserOrganizationSelect[]);
    }
  }

  function setUser(u: TUser) {
    u.name = [u.firstName, u.lastName].join(' ')
    user.value = u
  }

  function setOrganization(o: UserOrganizationSelect) {
    organization.value = o
  }

  function setOrganizations(o: UserOrganizationSelect[]) {
    organizations.value = o;
  }

  return {
    user,
    organization,
    organizations,

    isUser,
    isAdmin,
    isAuthed,

    setSessionFromPage,

    setOrganization,
    setOrganizations,
    setUser,
  };
})
