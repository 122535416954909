import { emitter } from "~/entrypoints/emitter";
import { h } from "vue";
import { isNil, snakeCase } from "lodash";
import { pinia } from "~/entrypoints/pinia";
import { t } from "~/entrypoints/i18n";
import { V8Icon } from "~/Components/icon";
import type { ColumnDef } from "@tanstack/vue-table";

import AccountsCombobox from "~/Components/v8/AccountsCombobox.vue";
import Input from "~/Components/v8/Input.vue";
import ItemsCombobox from "~/Components/v8/ItemsCombobox.vue";
import TaxCombobox from "~/Components/v8/TaxCombobox.vue";

import { INVOICE_ITEM_SELECTION } from "~/shared/eventTypeKeys";
import { OrganizationsTaxesIndex } from "~/types/serializers";
import { TAccount, TCellAction } from "~/types/Generic";
import { useInvoiceFormData } from "~/stores/invoiceFormData";

import { TProductItem, TProductItemRow } from "../../types";

const storeFormInvoice = useInvoiceFormData(pinia);

export const itemColumns: ColumnDef<TProductItem>[] = [
  {
    accessorKey: "name",
    header: () => t("item_name"),
    cell: ({ row, componentField }: TCellAction<TProductItem>) => {
      if (!row.original) return;

      return h(ItemsCombobox, {
        ...componentField,
        modelValue: componentField.modelValue,

        /**
         * Use the retrieved selection data (ProductItem) to populate
         * related ProductItem form fields
         *
         * @param value
         */
        "onUpdate:item": (value: TProductItem) => {
          emitter.emit(INVOICE_ITEM_SELECTION, {
            rowIndex: row.index,
            item: value,
          } as TProductItemRow);
        },
      });
    },
  },

  {
    accessorKey: "description",
    header: ({ column }) => t(snakeCase(column.id)),
    cell: (props: TCellAction<TProductItem>) => {
      const { row, componentField } = props;

      if (!row.original) return;

      return h(Input, {
        placeholder: t("item_description"),
        ...componentField,
      });
    },
  },

  {
    accessorKey: "accountId",
    header: () => t("account"),
    cell: ({ row, componentField }: TCellAction<TProductItem>) => {
      if (!row.original) return;

      const isEditMode = !isNil(row.original.id); // item has ID

      // NOTE: Note that Server also send 'account' in edit mode.
      let account: TAccount | null | undefined;

      if (isEditMode) {
        if (row.original.account && row.original.account.id) {
          account = row.original.account;
        }
      } else {
        // add new item
      }

      return h(AccountsCombobox, {
        account,
        ...componentField,
      });
    },
  },

  {
    accessorKey: "quantity",
    header: ({ column }) => t(snakeCase(column.id)),
    cell: ({ row, componentField }: TCellAction<TProductItem>) => {
      if (!row.original) return;

      return h(Input, {
        type: "number",
        placeholder: "0",
        ...componentField,
      });
    },
  },

  {
    accessorKey: "price",
    header: () => t("price"),
    cell: ({ row, componentField }: TCellAction<TProductItem>) => {
      if (!row.original) return;

      return h(Input, {
        type: "number",
        step: "0.01",
        min: 0,
        placeholder: 5.29,
        ...componentField,
        modelValue: Number(componentField.modelValue).toFixed(2),
      });
    },
  },

  {
    accessorKey: "discountAmount",
    header: ({ column }) => t(snakeCase(column.id)),
    cell: ({ row, componentField }: TCellAction<TProductItem>) => {
      if (!row.original) return;

      return h(Input, {
        type: "number",
        step: "0.01",
        ...componentField,
        modelValue: Number(componentField.modelValue).toFixed(2),
      });
    },
  },

  {
    accessorKey: "amount",
    header: () => t("amount"),
    cell: ({ row }) => {
      if (!row.original) return;

      let amount = 0;

      const price = Number(row.original.price);
      const quantity = Number(row.original.quantity);
      const discountAmount = Number(row.original.discountAmount);

      if (!Number.isNaN(price) && !Number.isNaN(quantity))
        amount = price * quantity;

      return (amount - discountAmount).toFixed(2);
    },
  },

  {
    accessorKey: "taxId",
    header: () => t("tax"),
    cell: ({ row, componentField }: TCellAction<TProductItem>) => {
      if (!row.original) return;

      return h(TaxCombobox, {
        ...componentField,
        "onUpdate:taxItem": (value: OrganizationsTaxesIndex) => {
          // to use the tax values
          storeFormInvoice.updateTaxForItem(row.index, value);
        },
      });
    },
  },

  {
    id: "actions",
    enableResizing: false,
    meta: {
      attributes: {
        class: "w-10",
      },
    },
    cell: (props: TCellAction<TProductItem>) => {
      return h(
        "button",
        {
          type: "button",
          class: "py-1 pl-2 pe-3 end-0 text-muted-foreground",
          onClick: (_event) => props.remove(props.row.index),
        },
        h(V8Icon, {
          icon: "lucide:x",
          class: "w-4 h-4",
        })
      );
    },
  },
];
