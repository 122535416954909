<script setup lang="ts">
import '@tanstack/vue-table'
import { emitter } from '~/entrypoints/emitter';
import { FlexRender, getCoreRowModel, RowData, useVueTable } from '@tanstack/vue-table';
import { INVOICE_ITEM_SELECTION } from '~/shared/eventTypeKeys';
import { extend, get, merge, pick } from 'lodash';
import { PlusIcon } from 'lucide-vue-next';
import { provide, ref } from 'vue';
import { tryOnMounted } from '@vueuse/core';
import { t } from '~/entrypoints/i18n';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '~/Components/ui/table';
import Button from '~/Components/ui/button/Button.vue';

import { itemColumns } from './columns';
import { organizationsAccounts, organizationsTaxes } from '~/api';
import { OrganizationsAccountsShow, OrganizationsTaxesIndex } from '~/types/serializers';
import { useModuleApiStore } from '~/stores/moduleApi';

import { FieldArray, FieldEntry } from 'vee-validate';
import { FormControl, FormField, FormItem } from '~/Components/ui/form';
import { TProductItem, TProductItemRow } from '../../types';

declare module '@tanstack/vue-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    attributes: any
  }
}

// NOTE: Need to this to have control on events
// or otherwise, inherited events from parent (vue-validate)
defineOptions({ inheritAttrs: false })

const props = defineProps<{
  fields: FieldEntry<unknown>[]
}>()

/**
 * Need to map get only `.value` since we pass in this component props
 * as FieldEntry (of vee-validate)
 */
const fieldsData = computed(() =>
  props.fields.map((field: FieldEntry) => toRaw(field.value)) as TProductItem[]
)

const table = useVueTable({
  get data() { return fieldsData.value },
  get columns() { return itemColumns },
  getCoreRowModel: getCoreRowModel()
})

const taxList = ref<OrganizationsTaxesIndex[]>()
const accountList = ref<OrganizationsAccountsShow[]>()

const moduleApi = useModuleApiStore()

emitter.on(INVOICE_ITEM_SELECTION, (data: unknown) => {
  const selectedProductItem = data as TProductItemRow
  const itemAtIndex = get(props.fields, selectedProductItem.rowIndex)

  if (itemAtIndex) {
    // use selected item, but retain some property: (accountId, taxId)
    if (selectedProductItem.item) {
      selectedProductItem.item = merge({}, selectedProductItem.item, pick(itemAtIndex.value, ['account', 'accountId', 'taxId']))
    }

    itemAtIndex.value = selectedProductItem.item
  }
})

/**
 * Use by 'add' button to add placeholder
 * when adding ProductItem object
 */
const itemInitialValue: TProductItem = {
  id: null,
  description: null,
  discountAmount: "0.0",
  itemId: null,
  name: null,
  price: "0.0",
  quantity: 0,
  taxId: null,
  uomId: null,
  accountId: 0
};

/**
 * Load ealier list of Tax and Account
 */
tryOnMounted(() => {
  loadTaxes()
  loadAccounts()
})

async function loadTaxes() {
  const response = await moduleApi.gotoIndex(organizationsTaxes.index, { accept: 'json' })
  taxList.value = response.taxes
}
async function loadAccounts() {
  const response = await moduleApi.fetchUrl(organizationsAccounts.index, { accept: 'json' })
  accountList.value = response.props.accounts
}

const computedAccounts = computed(() => accountList.value)

/**
 * provide/inject. Used by component in ProductItem
 * when mapping or showing popup list
 */
provide('taxList', { taxList })
provide('accountList', { accounts: computedAccounts })

</script>

<template>
  <div class="border-0 rounded-md">
    <Table class="border-separate border-spacing-0">
      <TableHeader class="[&_tr_th]:border-y [&_tr_th]:bg-primary-foreground">
        <TableRow v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id">
          <TableHead v-for="header in headerGroup.headers" :key="header.id"
            v-bind="header.column.columnDef.meta?.attributes"
            class="first:rounded-tl-md first:border-l last:rounded-tr-md last:border-r">
            <FlexRender v-if="!header.isPlaceholder" :render="header.column.columnDef.header"
              :props="header.getContext()" />
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody class="[&_tr:nth-last-child(2)_td]:border-b
          [&_tr:nth-last-child(2)_td:first-child]:rounded-bl-md
          [&_tr:nth-last-child(2)_td:last-child]:rounded-br-md">
        <FieldArray v-slot="{ push, remove }" name="items">
          <template v-if="table.getRowModel().rows?.length">

            <TableRow v-for="(row, index) in table.getRowModel().rows" :key="row.id">
              <TableCell v-for="cell in row.getVisibleCells()" :key="cell.id" class="first:border-l last:border-r">
                <FormField v-slot="{ componentField }" :name="`items[${index}].${cell.column.id}`">
                  <FormItem>
                    <FormControl>
                      <FlexRender :render="cell.column.columnDef.cell"
                        :props="extend({}, cell.getContext(), { remove, componentField })" />
                    </FormControl>
                  </FormItem>
                </FormField>
              </TableCell>
            </TableRow>

          </template>

          <TableRow v-else>
            <TableCell :colspan="itemColumns.length" class="h-24 text-center first:border-l last:border-r">
              {{ t('table.no_result') }}
            </TableCell>
          </TableRow>

          <TableRow class="hover:bg-transparent">
            <TableCell :colspan="itemColumns.length" class="p-0">
              <div class="flex my-4">
                <Button @click="push(itemInitialValue)" variant="ghost" type="button"
                  class="w-full border-2 border-dashed cursor-pointer border-zinc-400 hover:border-zinc-500 group">
                  <PlusIcon strokeWidth="3" class="mr-4 text-gray-400 group-hover:text-gray-600" /> <span
                    class="text-lg">{{ t('add_line') }}</span>
                </Button>
              </div>
            </TableCell>
          </TableRow>

        </FieldArray>
      </TableBody>
    </Table>
  </div>
</template>
