import { pinia } from '~/entrypoints/pinia';
import { router, usePage } from '@inertiajs/vue3'
import { useAuthSesion } from "~/stores/authSession";

const authSession = useAuthSesion(pinia)

export function useSubscribeInertiaEvent() {
  // console.log("useEventSubscripion!");

  authSession.setSessionFromPage(usePage());

  // REF: https://inertiajs.com/events

  // router.on("start", (event) => {
  //   console.log(`Starting a visit to ${event.detail.visit.url}`);
  // });

  // router.on("finish", (event) => {
  //   NProgress.done();
  // });

  router.on("success", (event) => {
    // console.log(`Successfully made a visit to ${event.detail.page.url}`);
    authSession.setSessionFromPage(event.detail.page);
  });

  // router.on("navigate", (event) => {
  //   console.log(`Navigated to ${event.detail.page.url}`);
  // });
}
