<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import {
  DateFormatter,
  type DateValue,
  getLocalTimeZone,
  parseAbsolute
} from '@internationalized/date'

import { Calendar as CalendarIcon } from 'lucide-vue-next'
import { Calendar } from '~/Components/ui/calendar'
import { Button } from '~/Components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from '~/Components/ui/popover'
import { cn } from '~/lib/utils'
import { useVModel } from '@vueuse/core'

defineOptions({
  inheritAttrs: false
})

const props = defineProps<{
  modelValue?: any
}>()

interface IEmits {
  (e: 'update:modelValue', payload: any): void
}
const emits = defineEmits<IEmits>()
const data = useVModel(props, 'modelValue', emits)

const df = new DateFormatter('en-UK', {
  dateStyle: 'short',
})

const dateValue = ref<DateValue>()
const formattedValue = computed(() => {
  if (!dateValue.value) return

  return df.format(dateValue.value.toDate(getLocalTimeZone()))
})

watch(dateValue, () => data.value = formattedValue.value)

if (data.value) {
  dateValue.value = parseAbsolute(data.value, getLocalTimeZone())
}
</script>

<template>
  <Popover>
    <PopoverTrigger as-child>
      <Button variant="outline" v-bind="$attrs" :class="cn(
        'text-left justify-start font-normal w-full',
        !dateValue && 'text-muted-foreground',
      )">
        <CalendarIcon class="w-4 h-4 mr-2" />
        {{ dateValue ? df.format(dateValue.toDate(getLocalTimeZone())) : "Pick a date" }}
      </Button>
    </PopoverTrigger>
    <PopoverContent class="w-auto p-0">
      <Calendar v-model="dateValue" initial-focus />
    </PopoverContent>
  </Popover>
</template>
