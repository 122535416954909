<script setup lang="ts">
import { cn } from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import TeamSwitcher from '~/pages/Organizations/Components/TeamSwitcher.vue';
import { PlusCircledIcon } from '@radix-icons/vue'
import { MenuRecordRaw, SidebarMenu } from "~/base/menu-ui";
import api from '~/api'
import { Link } from '@inertiajs/vue3';

const { t } = useI18n()

defineProps()

const menus: MenuRecordRaw[] = [
  // {
  //   name: t('sidebar.dashboard'),
  //   path: '/organization',
  //   icon: 'ic:twotone-home'
  // },
  {
    name: t('contacts'),
    path: api.organizationsContacts.index.path(),
    icon: 'mdi:contacts-outline'
  },
  {
    name: t('product_services'),
    path: api.organizationsItems.index.path(),
    icon: 'icon-park-outline:ad-product'
  },
  {
    name: t('page.category.business'),
    path: '/business',
    icon: 'mdi:business',
    children: [
      {
        name: t('invoices'),
        path: api.organizationsInvoices.index.path(),
        icon: 'ic:twotone-document-scanner'
      },
      {
        name: t('quotations'),
        path: api.organizationsQuotations.index.path(),
        icon: 'ic:round-request-quote',
      },
      {
        name: t('sale_orders'),
        path: api.organizationsSaleOrders.index.path(),
        icon: 'icon-park-twotone:sales-report'
      },
      {
        name: t('credit_notes'),
        path: api.organizationsCreditNotes.index.path(),
        icon: 'fa6-solid:money-check-dollar'
      },
      {
        name: t('delivery_orders'),
        path: api.organizationsDeliveryOrders.index.path(),
        icon: 'mage:delivery-truck',
      }
    ]
  },
  {
    name: t('page.category.accounting'),
    path: '/accounting',
    icon: 'mdi:google-spreadsheet',
    children: [
      // {
      //   name: t('opening_balance'),
      //   path: '/cp/opening-balances',
      //   icon: 'mdi:account-balance-wallet'
      // },
      {
        name: t('bank_accounts'),
        path: api.organizationsBankAccounts.index.path(),
        icon: 'mdi:bank'
      },
      {
        name: t('chart_accounts'),
        path: api.organizationsAccounts.index.path(),
        icon: 'mdi:bank-plus'
      },
      {
        name: t('tax_rates'),
        path: api.organizationsTaxes.index.path(),
        icon: 'tabler:tax'
      }
    ]
  },
  {
    name: t('page.category.control_panel'),
    path: '/cp',
    icon: 'carbon:ibm-z-os-ai-control-interface',
    children: [
      // {
      //   name: t('page.control_panel.company_profile'),
      //   path: '/cp/profile',
      //   icon: 'ri:profile-line'
      // },
      // {
      //   name: t('page.control_panel.company_settings'),
      //   path: '/cp/settings',
      //   icon: 'mdi:shop-settings-outline'
      // },
      {
        name: t('page.control_panel.members'),
        path: api.organizationsMembers.index.path(),
        icon: 'ri:folder-user-line'
      },
      // {
      //   name: t('page.control_panel.subscription'),
      //   path: '/organization/subscription',
      //   icon: 'uil:bill'
      // },
    ]
  }
]

</script>

<template>
  <div :class="cn('pb-12 border-r', $attrs.class ?? '')">
    <div class="py-4 space-y-1">
      <div class="w-full px-3 pb-3 border-b">
        <TeamSwitcher>
          <PlusCircledIcon class="w-5 h-5 mr-2" />
          <Link
            :href="api.usersOrganizations.new.path()"
            as="button"
            class="flex justify-start w-full"
          >
            {{ t('sidebar.create_new_organization') }}
          </Link>
        </TeamSwitcher>
      </div>

      <div class="py-3">
        <SidebarMenu
          :menus="menus"
          :defaultActive="[
            'Business',
            'Accounting',
            'Control Panel'
          ]"
        />
      </div>
    </div>
  </div>
</template>
