<script setup lang="ts">
import { h } from 'vue'
import { forEach } from 'lodash'
import { router } from '@inertiajs/vue3'
import startCase from 'lodash/startCase'
import { useI18n } from 'vue-i18n';
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import { useForm } from 'vee-validate'
import { Input } from '~/Components/ui/input'
import { Card } from '~/Components/ui/card'
import { MainContentWrap } from '~/Components/Wrapper'
import currencies from '~/data/currencies'
import months from '~/data/months'
import api from '~/api'

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/Components/ui/form'
import { Separator } from '~/Components/ui/separator'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~/Components/ui/select'
import { Button } from '~/Components/ui/button'

defineProps<{
  countries?: any,
  organization_types?: any,
  errors?: any,
}>()

const organizationFormSchema = toTypedSchema(
  z.object({
    organization: z.object({
      name: z.string(),
      trade_name: z.string(),
      organization_type: z.string(),
      country_code: z.string(),
      default_currency_code: z.string(),
      financial_year_end_day: z.string(),
      financial_year_end_month: z.string()
    })
  })
)

const { handleSubmit, resetForm } = useForm({
  validationSchema: organizationFormSchema
})

const onSubmit = handleSubmit((values, actions) => {
  router.post(api.usersOrganizations.create.path(), values, {
    onStart: (visit) => {
      console.log(visit)
    },
    onSuccess: (page) => {
      console.log("success", page)
    },
    onError:(err) => {
      console.error("err", err)
      forEach(err, (value, key) => {
        let formName: any = `organization.${key}`

        actions.setFieldError(formName, value)
      })
    }
  })
})
</script>

<template>
  <div class="grid grid-cols-1 gap-4 px-4 pt-6 lg:px-8 xl:grid-cols-2 xl:gap-4">
    <Card>
      <MainContentWrap class="space-y-6">
        <div>
          <h3 class="text-lg font-medium">
            {{ $t('organization.title') }}
          </h3>
          <p class="text-sm text-muted-foreground">
            {{ $t('organization.create_new') }}
          </p>
        </div>
        <Separator />
        <form class="space-y-8" @submit="onSubmit">
          <FormField v-slot="{ componentField }" name="organization.name">
            <FormItem>
              <FormLabel>{{ $t('organization.name') }}</FormLabel>
              <FormControl>
                <Input type="text" placeholder="Jourdein" v-bind="componentField" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="organization.trade_name">
            <FormItem>
              <FormLabel>{{ $t('organization.trade_name') }}</FormLabel>
              <FormControl>
                <Input type="text" placeholder="Rent2u Inc" v-bind="componentField" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="organization.organization_type">
            <FormItem>
              <FormLabel>{{ $t('organization.type') }}</FormLabel>
              <Select v-bind="componentField">
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select..." />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem v-for="organization_type in organization_types" :key="organization_type[0]" :value="organization_type[1]">
                      {{ organization_type[0] }}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
              <FormDescription>
                {{ $t('organization.type_description') }}
              </FormDescription>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="organization.country_code">
            <FormItem>
              <FormLabel>{{ $t('country') }}</FormLabel>
              <Select v-bind="componentField">
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select..." />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem v-for="country in countries" :key="country[0]" :value="country[1]">
                      {{ country[0] }} - {{ country[1] }}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
              <FormDescription>
                {{ $t('organization.country_description') }}
              </FormDescription>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="organization.default_currency_code">
            <FormItem>
              <FormLabel>{{ $t('currency') }}</FormLabel>
              <Select v-bind="componentField">
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select..." />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem v-for="currency in currencies" :key="currency[0]" :value="currency[1]">
                      {{ currency[1] }} - {{ startCase(currency[0]) }}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          </FormField>
          <div class="space-y-2">
            <span class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">Last day of your financial year</span>
            <div class="flex gap-2">
              <FormField v-slot="{ componentField }" name="organization.financial_year_end_day">
                <FormItem class="w-32">
                  <Select v-bind="componentField">
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Day" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem v-for="day in 31" :key="day" :value="day.toString()">
                          {{ day }}
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              </FormField>
              <FormField v-slot="{ componentField }" name="organization.financial_year_end_month">
                <FormItem class="grow">
                  <Select v-bind="componentField">
                    <SelectTrigger id="month">
                      <SelectValue placeholder="Month" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem v-for="month in months" :key="month[1]" :value="month[0]">
                        {{ month[1] }}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              </FormField>
            </div>
          </div>
          <div class="flex justify-start gap-2">
            <Button type="submit">
              {{ $t('form.button.create') }}
            </Button>
            <Button
              type="button"
              variant="outline"
              @click="resetForm"
            >
              {{ $t('form.button.reset') }}
            </Button>
          </div>
        </form>
      </MainContentWrap>
    </Card>
  </div>
</template>
