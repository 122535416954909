<script setup lang="ts">
import { Menu, Package2, Search } from 'lucide-vue-next'
import { Link } from '@inertiajs/vue3'
import { Button } from '~/Components/ui/button'
import { Input } from '~/Components/ui/input'
import { Sheet, SheetContent, SheetTrigger } from '~/Components/ui/sheet'
import ProfileAction from './Navbar/ProfileAction.vue';
import ModeToggle from '~/Components/ModeToggle.vue'

const appName = 'ERP'
</script>

<template>
  <header class="sticky top-0 z-40 flex items-center h-16 gap-4 px-4 border-b bg-background md:px-6">
    <nav class="flex-col hidden gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
      <div class="grid w-full md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]">
        <Link href="/" class="flex items-center gap-2 text-lg font-semibold md:text-base">
          <Package2 class="w-6 h-6" />
          <span>{{ appName }}</span>
        </Link>
        <!-- <a href="#" class="transition-colors text-foreground hover:text-foreground">Dashboard</a> -->
      </div>
    </nav>

    <Sheet>
      <SheetTrigger as-child>
        <Button
          variant="outline"
          size="icon"
          class="shrink-0 md:hidden"
        >
          <Menu class="w-5 h-5" />
          <span class="sr-only">Toggle navigation menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left">
        <nav class="grid gap-6 text-lg font-medium">
          <a
            href="#"
            class="flex items-center gap-2 text-lg font-semibold"
          >
            <Package2 class="w-6 h-6" />
            <span class="sr-only">Acme Inc</span>
          </a>
          <a href="#" class="hover:text-foreground">
            Dashboard
          </a>
          <a
            href="#"
            class="text-muted-foreground hover:text-foreground"
          >
            Orders
          </a>
          <a
            href="#"
            class="text-muted-foreground hover:text-foreground"
          >
            Products
          </a>
          <a
            href="#"
            class="text-muted-foreground hover:text-foreground"
          >
            Customers
          </a>
          <a
            href="#"
            class="text-muted-foreground hover:text-foreground"
          >
            Analytics
          </a>
        </nav>
      </SheetContent>
    </Sheet>
    <div class="flex items-center w-full gap-4 md:ml-auto md:gap-2 lg:gap-4">
      <form class="flex-1 ml-auto sm:flex-initial">
        <div class="relative">
          <Search class="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            type="search"
            placeholder="Search products..."
            class="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px]"
          />
        </div>
      </form>

      <ModeToggle />
      <ProfileAction />
    </div>
  </header>
</template>
