<script setup lang="ts">
import PageHeading from '~/Components/v8/PageHeading.vue';
import DataTable from './components/DataTable.vue';
import PagyMetadata from '~/types/Metadata';
import { columns } from './components/columns';
import { usePage } from '@inertiajs/vue3';
import { organizationsCreditNotes } from '~/api';
import { OrganizationsCreditNotesIndex } from '~/types/serializers';

defineProps<{ credit_notes: OrganizationsCreditNotesIndex[], metadata: PagyMetadata }>()
const moduleApi = useModuleApiStore()
moduleApi.setVersion(usePage().version)
moduleApi.setNew(organizationsCreditNotes.new)
moduleApi.setShow(organizationsCreditNotes.show)
moduleApi.setEdit(organizationsCreditNotes.edit)
moduleApi.setDestroy(organizationsCreditNotes.destroy)

provide('action-show', true)
</script>

<template>
  <div class="flex-col flex-1 h-full p-8 space-y-8 md:flex">
    <PageHeading pageId="credit_notes" />
    <DataTable :data="credit_notes" :columns="columns" />
  </div>
</template>
