// JsFromRoutes CacheKey 35d3f5dd870500aac1bed268f08a7760
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  void: /* #__PURE__ */ definePathHelper('patch', '/organization/quotations/:id/void'),
  submit: /* #__PURE__ */ definePathHelper('patch', '/organization/quotations/:id/submit'),
  approve: /* #__PURE__ */ definePathHelper('patch', '/organization/quotations/:id/approve'),
  index: /* #__PURE__ */ definePathHelper('get', '/organization/quotations'),
  create: /* #__PURE__ */ definePathHelper('post', '/organization/quotations'),
  new: /* #__PURE__ */ definePathHelper('get', '/organization/quotations/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/organization/quotations/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/organization/quotations/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/organization/quotations/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/organization/quotations/:id'),
}
