<script setup lang="ts">
import { compact, startCase } from 'lodash';
import { computed, ref } from 'vue';
import { tryOnMounted } from '@vueuse/core';
import { usePage } from '@inertiajs/vue3';

// shadcn
import { Card, CardContent } from '~/Components/ui/card';
import Separator from '~/Components/ui/separator/Separator.vue';

// custom
import CenterWrap from '~/Components/Wrapper/CenterWrap.vue';
import PageHeading from '~/Components/v8/PageHeading.vue';
import PlaceholderLogo from '~/Icons/PlaceholderLogo.vue';

import { dateFromDateTimeZone } from '~/lib/utils';
import { t } from '~/entrypoints/i18n';
import { useAuthSesion } from '~/stores/authSession';
import { organizations, organizationsDeliveryOrders } from '~/api';
import { OrganizationsDeliveryOrdersShow } from '~/types/serializers';
import { useModuleApiStore } from '~/stores/moduleApi';

// configure API use in this component.
const API = organizationsDeliveryOrders

const props = defineProps<{ delivery_order: OrganizationsDeliveryOrdersShow }>()

const amountWithCurrency = computed(() => `${props.delivery_order.currencyCode}${Number(props.delivery_order.amount).toFixed(2)}`)
const moduleApi = useModuleApiStore()
const organization = ref()
const userSession = useAuthSesion()

tryOnMounted(async () => {
  const result = await moduleApi.fetchUrl(organizations.show, { accept: 'json', version: usePage().version })
  organization.value = result.props.organization
})
</script>

<template>
  <CenterWrap>
    <PageHeading pageId="deliver_order" class="mt-8" hideDescription :backButton="API.index" />
    <Card>
      <CardContent class="p-12">
        <div class="grid grid-cols-2 mb-16">
          <div class="w-fit">
            <PlaceholderLogo />
          </div>
          <div class="flex flex-col items-end col-start-2 col-end-2">
            <span>{{ startCase(userSession.user?.name) }}</span>
            <span>{{ userSession.user?.phoneNumber }}</span>
            <span>{{ organization?.name }}</span>
          </div>
        </div>

        <div class="grid grid-cols-[2fr_minmax(0,140px)_minmax(0,140px)_1fr] gap-4 mb-14 ">
          <div>
            <h6>{{ t('prepared_for') }}</h6>
            <div>
              <div>{{ delivery_order.customer.name }}</div>
              <div>{{ delivery_order.billingAddress.streetAddress }}</div>
              <div>{{ compact([delivery_order.billingAddress.postcode, delivery_order.billingAddress.city]).join(' ') }}</div>
              <div>{{ compact([delivery_order.billingAddress.state, delivery_order.billingAddress.country]).join(',') }}</div>
            </div>
          </div>

          <div class="grid">
            <div class="mb-5">
              <h6>{{ t('issue_date') }}</h6>
              <div>{{ dateFromDateTimeZone(delivery_order.issueDate) }}</div>
            </div>

            <div class="mb-5">
              <h6>{{ t('due_date') }}</h6>
              <div>{{ dateFromDateTimeZone(delivery_order.dueDate) }}</div>
            </div>
          </div>

          <div class="grid">
            <div>
              <h6>{{ t('delivery_order_no') }}</h6>
              <div>{{ delivery_order.number }}</div>
            </div>
            <div>
              <h6>{{ t('reference') }}</h6>
              <div>{{ delivery_order.reference }}</div>
            </div>
          </div>

          <div class="flex flex-col items-end justify-start">
            <h6>{{ startCase(t('amount_due')) }} ({{ delivery_order.currencyCode }})</h6>
            <div class="text-3xl">{{ amountWithCurrency }}</div>
          </div>
        </div>

        <Separator class="h-1 mb-3 bg-slate-700" />

        <div class="grid grid-cols-[2fr_1fr_100px_1fr] mb-4">
          <h6>{{ t('description') }}</h6>
          <h6 class="text-right">{{ t('price') }}</h6>
          <h6 class="text-right">{{ t('quantity') }}</h6>
          <h6 class="text-right">{{ startCase(t('line_total')) }}</h6>
        </div>

        <div v-for="item in delivery_order.items">
          <div class="grid grid-cols-[2fr_1fr_100px_1fr]">
            <div>
              <div>{{ item.name }}</div>
              <div>
                {{ item.description }}
              </div>
            </div>

            <div class="text-right">{{ Number(item.price).toFixed(2) }}</div>
            <div class="text-right">{{ item.quantity }}</div>
            <div class="text-right">{{ Number(item.totalAmount).toFixed(2) }}</div>
          </div>

          <Separator class="my-2" />
        </div>

        <div class="grid grid-cols-2 mt-20">
          <div class="grid col-start-2">
            <div>
              <div class="grid grid-cols-2">
                <div class="text-right">{{ t('subtotal') }}</div>
                <div class="text-right">{{ Number(delivery_order.subTotalAmount).toFixed(2) }}</div>

                <div class="text-right">{{ startCase(t('total_discount_amount')) }}</div>
                <div class="text-right">{{ Number(delivery_order.totalDiscountAmount).toFixed(2) }}</div>
              </div>
              <Separator class="my-3" />
              <div class="grid grid-cols-2">
                <div class="text-right">{{ t('total') }}</div>
                <div class="text-right">{{ Number(delivery_order.amount).toFixed(2) }}</div>
              </div>

              <div class="grid grid-cols-2 py-3 mt-6 border-t-4 border-double">
                <div class="text-right text-muted-foreground">{{ startCase(t('amount_due')) }}
                  ({{ delivery_order.currencyCode }})</div>
                <div class="text-right">{{ amountWithCurrency }}</div>
              </div>

            </div>
          </div>
        </div>

        <div class="mt-20">
          <h6>{{ t('notes') }}</h6>
          <div>{{ delivery_order.notes }}</div>
        </div>

      </CardContent>
    </Card>
  </CenterWrap>
</template>
