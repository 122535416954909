import { forIn, isEmpty, startCase } from "lodash";
import { useToast } from "~/Components/ui/toast";

const { toast } = useToast();

export function useErrorHandler() {
  function generic(formErrors: any) {
    if (isEmpty(formErrors)) return

    watch(formErrors, (errors) => {
      forIn(errors, (value, key) => {
        toast({
          title: startCase(key),
          description: value,
          variant: "destructive",
        });
      });
    });
  }
  return {
    generic
  }
}
