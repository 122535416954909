<script setup lang="ts">
import { ref, watch } from 'vue';
import { Popover, PopoverContent, PopoverTrigger } from '~/Components/ui/popover';
import { createReusableTemplate, tryOnMounted, useVModel } from '@vueuse/core';
import { useModuleApiStore } from '~/stores/moduleApi';
import { usePage } from '@inertiajs/vue3';
import { organizationsContacts } from '~/api';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '~/Components/ui/command';
import { OrganizationsContactsIndex } from '~/types/serializers';
import { Input } from '~/Components/ui/input'
import { extend, isEmpty, omitBy } from 'lodash';
import { TCustomer } from '~/types/Generic';

/**
  [Radix-Vue] Disable inheritAttrs.
  - issue/warn appear since Popover is the 'root'.
  - naturally, the props should be inherited to Input
 */
defineOptions({ inheritAttrs: false })

const props = defineProps<{
  defaultValue?: string | number
  modelValue?: string | number,
  contact?: TCustomer
}>()

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number): void
  (e: 'update:contact', payload: string | number): void
}>()

const moduleApi = useModuleApiStore()

const modelValue = useVModel(props, 'modelValue', emits, { passive: true })
const theCustomer = useVModel(props, 'contact', emits, { passive: true })

const isOpen = ref(false)
const [UseTemplate, UserList] = createReusableTemplate()
const items = ref<OrganizationsContactsIndex[]>()
const selectedName = ref('')

/**
Load contacts list API
 */
tryOnMounted(async () => {
  moduleApi.setVersion(usePage().version)
  const response = await moduleApi.gotoIndex(organizationsContacts.index, { accept: 'json' })
  items.value = cleanupContactFromEmptyString(response.contacts)
})

/**
If edit mode, capture the object
 */
if (props.contact) {
  selectedName.value = props.contact.name
  modelValue.value = props.contact.id
}

/**
Explicitly set the field to 'undefined' if empty string
 */
watch(selectedName, (name) => {
  if (name.length == 0) modelValue.value = undefined
})

const invoiceFormState = ref({})

/**
 * Selection input event for selecting
 * customer/user/contact the bill/invoice/quote, etc
 *
 * @param pickedItem Selected Customer/User/Contact
 */
async function onItemSelect(pickedItem: OrganizationsContactsIndex) {
  theCustomer.value = pickedItem
  selectedName.value = pickedItem.name
  modelValue.value = pickedItem.id
  isOpen.value = false

  const response = await moduleApi.fetchUrl(organizationsContacts.show, {accept: 'json', params: {id: pickedItem.id}})
  extend(invoiceFormState.value, {contact: response.props.contact})
}

// [Radix-Vue] 'CommandItem' or 'Comboxbox' cannot have value=''
// this method cleanup property 'name' from value '' or null
function cleanupContactFromEmptyString(contactRecords: OrganizationsContactsIndex[]) {
  const omitted = omitBy(contactRecords, o => isEmpty(o.name))
  return omitted as OrganizationsContactsIndex[]
}
</script>

<template>
  <Popover v-model:open="isOpen">
    <PopoverTrigger as-child>
      <Input type="text" placeholder="Select item" v-model="selectedName" v-bind="$attrs" />
    </PopoverTrigger>
    <PopoverContent class="w-[200px] p-0" align="start">
      <UserList />
    </PopoverContent>
  </Popover>

  <UseTemplate>
    <Command>
      <CommandInput :placeholder="$t('filter_item', { item: 'status' })" />
      <CommandList>
        <CommandEmpty>{{ $t('no_result_found') }}</CommandEmpty>
        <CommandGroup>
          <CommandItem
            v-for="item of items"
            :key="item.id"
            :value="item.name"
            @select="onItemSelect(item)"
            v-if="items"
          >
            {{ item.name }}
          </CommandItem>
        </CommandGroup>
      </CommandList>
    </Command>
  </UseTemplate>
</template>
