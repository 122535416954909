<script setup lang="ts">
import { computed } from 'vue';
import { PathHelper } from '@js-from-routes/client';

import Button from '~/Components/ui/button/Button.vue';
import InvoicesApi from '~/api/Organizations/InvoicesApi';
import { useModuleApiStore } from '~/stores/moduleApi';

const props = defineProps<{
  status: string,
  id?: number,
}>()
// draft: 'draft',
//     # pending: 'pending',
//   approved: 'approved',
//     paid: 'paid'
//     # void: 'void'


let params = {
  id: props.id
}

type TAction = {
  name: string,
  label: string,
  pathHelper: PathHelper
}

const nextAction = computed((): TAction => {
  let action: TAction = {
    name: 'Save',
    label: 'save',
    pathHelper: InvoicesApi.approve
  }

  // console.log('props.status', props.status)

  switch (props.status) {
    case 'draft':
      action.name = 'Send invoice'
      action.label = 'send_invoice'
      break;
    case 'approved':
      action.name = 'Record payment'
      action.label = 'record_payment'
    default:
      action.name = 'Save'
      action.label = 'save'
      break;
  }
  return action
})

const moduleApi = useModuleApiStore()

const onClick = async () => {
  // console.log('onClick')
  const response = await moduleApi.fetchUrl(nextAction.value.pathHelper, {params: params})
  console.log('response', response)
}

</script>

<template>
<Button @click="() => onClick()">
  {{ $t(nextAction.label) }}
</Button>
</template>
