<script setup lang="ts">
import { computed, ref, unref, watch } from 'vue'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'

import { Button } from '~/Components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/Components/ui/form'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '~/Components/ui/dialog'
import { Input } from '~/Components/ui/input'
import { useModuleApiStore } from '~/stores/moduleApi'
import { Select, SelectContent, SelectGroup, SelectItem, SelectValue, SelectTrigger } from '~/Components/ui/select'
import { getKeyByValue } from '~/lib/utils'
import { snakeCase } from 'lodash'
import { Textarea } from '~/Components/ui/textarea'

const props = defineProps<{
  dialogOpen: boolean
}>()
const emit = defineEmits<{
  (e: 'toggle', value: boolean): void
}>()
const open = ref(props.dialogOpen)
watch(() => props.dialogOpen, (newState) => open.value = newState,
  { immediate: true }
)

const moduleApi = useModuleApiStore()

const formSchema = toTypedSchema(z.object(
  {
    name: z.string().min(2).max(50),
    code: z.string(),
    type: z.string().optional(),
    description: z.string()
  }
))

function updateEditedWithBankType(bankTypes: any) {
  if (!moduleApi.isModeEdit) return

  moduleApi.currentEditRecord.type = <any>getKeyByValue(bankTypes, moduleApi.currentEditRecord.type)
}

watch(() => moduleApi.responseProps, (n) => {
  if (!n) return

  const bankTypes = unref(n.types)
  updateEditedWithBankType(bankTypes)
})

const bankTypes = computed(() => moduleApi.responseProps?.types)

const isEditMode = computed(() => moduleApi.currentEditRecord)

function removeBankTypeIfBank(itemFormData: any) {
  console.log('moduleApi.currentEditRecord', itemFormData)
  if (!itemFormData.type) {
    delete itemFormData.type
  }

  return itemFormData
}

const onSubmit = (values: any) => {
  emit('toggle', open.value = false)

  values.type = snakeCase(values.type)

  if (moduleApi.currentEditRecord) {
    const processedPayload = removeBankTypeIfBank(values)
    moduleApi.gotoUpdate({ id: moduleApi.currentEditRecord.id, ...processedPayload }, moduleApi.currentEditRecord.id)
  } else {
    moduleApi.gotoCreate({
      account: values
    })
  }
}
</script>
<template>
  <Dialog v-bind:open="open" @update:open="(value) => $emit('toggle', value)">
    <DialogContent class="sm:max-w-[625px]">
      <DialogHeader>
        <DialogTitle>{{ $t(moduleApi.isModeEdit ? 'edit_account' : 'add_account') }}</DialogTitle>
      </DialogHeader>

      <Form :validation-schema="formSchema" :initial-values="moduleApi.currentEditRecord" as="form" @submit="onSubmit">
        <div class="space-y-5">
          <FormField v-slot="{ componentField }" name="code">
            <FormItem>
              <FormLabel>{{ $t('code') }}</FormLabel>
              <FormControl>
                <Input type="text" placeholder="Code" v-bind="componentField" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>

          <FormField v-slot="{ componentField }" name="name">
            <FormItem>
              <FormLabel>{{ $t('name') }}</FormLabel>
              <FormControl>
                <Input type="text" placeholder="John Doe" v-bind="componentField" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>

          <FormField v-slot="{ componentField }" name="type">
            <template v-if="isEditMode ? (moduleApi.currentEditRecord && componentField.modelValue) : true">
              <FormItem>
                <FormLabel>{{ $t('bank_type') }}</FormLabel>
                <Select v-bind="componentField">
                  <SelectTrigger class="w-[180px]">
                    <SelectValue placeholder="Select a type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem v-for="(type, key) in bankTypes" :value="key.toString()" :key="type">
                        {{ type }}
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormItem>
            </template>
          </FormField>

          <FormField v-slot="{ componentField }" name="description">
            <FormItem>
              <FormLabel>{{ $t('description') }}</FormLabel>
              <FormControl>
                <Textarea placeholder="Type your decription here." v-bind="componentField" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>

        <DialogFooter class="mt-5">
          <Button type="button" @click="emit('toggle', open = false)" variant="outline">{{ $t('cancel') }}</Button>
          <Button type="submit">{{ $t('save_changes') }}</Button>
        </DialogFooter>
      </Form>
    </DialogContent>
  </Dialog>
</template>
