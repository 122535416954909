<script setup lang="ts">
import { ref } from 'vue'
import { cn } from '~/lib/utils'
import { Check, ChevronsUpDown } from 'lucide-vue-next'
import { Button } from '~/Components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '~/Components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/Components/ui/popover'
import { SelectRootEmits } from 'radix-vue'
import { useVModel } from '@vueuse/core'

type Item = {
  value: string,
  label: string
}

const props = defineProps<{
  listItems: Item[],
  defaultValue?: string | number
  modelValue?: string | number
}>()
const emits = defineEmits<SelectRootEmits>()
const value = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue,
})

const open = ref(false)
// const value = ref('')

function filterFunction(list: any, searchTerm: string) {
  const result = list.filter((item: Item) => {
    return item.label.toLowerCase().includes(searchTerm.toLowerCase())
  })
  return result
}
</script>

<template>
  <div class="flex w-full">
    <Popover v-model:open="open">
      <PopoverTrigger as-child>
        <Button variant="outline" role="combobox" :aria-expanded="open" class="min-w-[200px] justify-between w-full">
          {{ value
            ? listItems.find((item) => item.value === value)?.label
            : "Select..." }}
          <ChevronsUpDown class="w-4 h-4 ml-2 opacity-50 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent class="min-w-[200px] p-0">
        <Command :filter-function="filterFunction" :display-value="(v: any) => v.label">
          <CommandInput class="h-9" placeholder="Search..." />
          <CommandEmpty>{{ $t('search_term_not_found') }}</CommandEmpty>
          <CommandList>
            <CommandGroup>
              <CommandItem v-for="item in listItems" :key="item.value" :value="item" @select="(ev) => {
                if (typeof ev.detail.value === 'string') {
                  value = ev.detail.value
                }
                else {
                  const theItem: Item = <any>ev.detail.value
                  value = theItem.value
                }
                open = false
              }">
                {{ item.label }}
                <Check :class="cn(
                  'ml-auto h-4 w-4',
                  value === item.value ? 'opacity-100' : 'opacity-0',
                )" />
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  </div>
</template>
