// JsFromRoutes CacheKey 05569efe34f52f5184b2fb8536107c2d
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  switch: /* #__PURE__ */ definePathHelper('patch', '/users/organizations/:id/switch'),
  create: /* #__PURE__ */ definePathHelper('post', '/users/organizations'),
  new: /* #__PURE__ */ definePathHelper('get', '/users/organizations/new'),
}
