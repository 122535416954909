<template>
  <svg width="100%" height="100%" viewBox="0 0 800 600" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;">
    <g>
      <g>
        <path
          d="M533.083,301.361l-0,-118.296c-0.011,-10.542 -5.658,-20.311 -14.787,-25.581l-103.509,-59.148c-9.139,-5.277 -20.435,-5.277 -29.574,-0l-103.509,59.148c-9.129,5.27 -14.776,15.039 -14.787,25.581l0,118.296c0.011,10.542 5.658,20.31 14.787,25.581l103.509,59.148c9.139,5.277 20.435,5.277 29.574,0l103.509,-59.148c9.129,-5.271 14.776,-15.039 14.787,-25.581Z"
          style="fill:none;fill-rule:nonzero;stroke:#000;stroke-width:29.57px;" />
        <path d="M333.459,127.022l66.541,38.447l66.541,-38.447"
          style="fill:none;fill-rule:nonzero;stroke:#000;stroke-width:29.57px;stroke-linecap:butt;" />
        <path d="M333.459,357.404l-0,-76.745l-66.542,-38.446"
          style="fill:none;fill-rule:nonzero;stroke:#000;stroke-width:29.57px;stroke-linecap:butt;" />
        <path d="M270.91,167.687l129.09,74.674l129.09,-74.674"
          style="fill:none;fill-rule:nonzero;stroke:#000;stroke-width:29.57px;stroke-linecap:butt;" />
        <path d="M400,391.266l0,-149.053"
          style="fill:none;fill-rule:nonzero;stroke:#000;stroke-width:29.57px;stroke-linecap:butt;" />
        <path
          d="M454.321,227.276l24.84,-14.192l39.277,99.232l-24.286,13.876l-3.416,-8.993l-35.124,20.067l-3.415,12.897l-37.154,21.227l39.278,-144.114Zm27.721,68.074l-10.466,-31.116l-9.877,43.194l20.343,-12.078Z"
          style="fill-rule:nonzero;" />
      </g>
      <g>
        <path
          d="M258.198,452.25c0.29,-0.765 1.023,-1.271 1.842,-1.271c2.571,0 8.093,0 10.389,0c0.622,0 1.179,0.385 1.4,0.966c2.353,6.204 15.351,40.471 18.931,49.911c0.207,0.546 0.133,1.159 -0.199,1.64c-0.332,0.481 -0.879,0.768 -1.463,0.768l-13.548,-0c-0.392,-0 -0.743,-0.245 -0.878,-0.613c-0.474,-1.293 -1.532,-4.181 -1.532,-4.181l-16.138,0c0,0 -0.917,2.503 -1.426,3.891c-0.199,0.542 -0.715,0.903 -1.292,0.903c-2.743,-0 -10.635,-0 -14.123,-0c-0.384,-0 -0.743,-0.189 -0.961,-0.505c-0.218,-0.316 -0.267,-0.719 -0.131,-1.078c3.008,-7.931 16.41,-43.262 19.129,-50.431Zm11.338,35.477l-4.465,-14.809l-4.443,14.809l8.908,-0Z"
          style="fill-rule:nonzero;" />
        <path
          d="M310.574,450.979c-0.457,0 -0.896,0.182 -1.22,0.505c-0.323,0.324 -0.505,0.763 -0.505,1.22c0,7.681 0,38.817 0,48.602c0,0.784 0.312,1.537 0.866,2.091c0.555,0.555 1.307,0.867 2.092,0.867c7.192,-0 25.502,-0 32.278,-0c1.42,-0 2.572,-1.152 2.572,-2.573c-0,-2.614 -0,-6.626 -0,-9.13c-0,-1.258 -1.02,-2.278 -2.278,-2.278c-5.258,0 -17.795,0 -17.795,0l-0,-37.154c-0,-1.187 -0.963,-2.15 -2.15,-2.15c-3.559,0 -10.603,0 -13.86,0Z"
          style="fill-rule:nonzero;" />
        <path
          d="M358.858,477.661c0,-3.834 0.733,-7.456 2.197,-10.864c1.465,-3.409 3.462,-6.378 5.992,-8.908c2.529,-2.53 5.499,-4.527 8.907,-5.991c3.409,-1.465 7.03,-2.197 10.865,-2.197c3.834,-0 7.456,0.732 10.864,2.197c3.409,1.464 6.378,3.461 8.908,5.991c2.529,2.53 4.527,5.499 5.991,8.908c1.465,3.408 2.197,7.03 2.197,10.864c0,3.835 -0.732,7.456 -2.197,10.865c-1.464,3.408 -3.462,6.377 -5.991,8.907c-2.53,2.53 -5.499,4.527 -8.908,5.992c-3.408,1.464 -7.03,2.197 -10.864,2.197c-3.835,-0 -7.456,-0.733 -10.865,-2.197c-3.408,-1.465 -6.378,-3.462 -8.907,-5.992c-2.53,-2.53 -4.527,-5.499 -5.992,-8.907c-1.464,-3.409 -2.197,-7.03 -2.197,-10.865Zm16.777,0c-0,1.545 0.292,2.996 0.878,4.354c0.586,1.358 1.385,2.543 2.397,3.555c1.012,1.012 2.197,1.811 3.555,2.397c1.358,0.585 2.809,0.878 4.354,0.878c1.544,0 2.995,-0.293 4.354,-0.878c1.358,-0.586 2.543,-1.385 3.554,-2.397c1.012,-1.012 1.811,-2.197 2.397,-3.555c0.586,-1.358 0.879,-2.809 0.879,-4.354c-0,-1.544 -0.293,-2.996 -0.879,-4.354c-0.586,-1.358 -1.385,-2.543 -2.397,-3.555c-1.011,-1.011 -2.196,-1.81 -3.554,-2.396c-1.359,-0.586 -2.81,-0.879 -4.354,-0.879c-1.545,0 -2.996,0.293 -4.354,0.879c-1.358,0.586 -2.543,1.385 -3.555,2.396c-1.012,1.012 -1.811,2.197 -2.397,3.555c-0.586,1.358 -0.878,2.81 -0.878,4.354Z"
          style="fill-rule:nonzero;" />
        <path
          d="M469.984,467.859c-0.465,0.662 -1.37,0.839 -2.052,0.402c-0.708,-0.455 -1.519,-0.82 -2.425,-1.105c-1.438,-0.453 -2.849,-0.679 -4.234,-0.679c-1.544,0 -2.956,0.28 -4.234,0.839c-1.278,0.559 -2.383,1.331 -3.315,2.317c-0.932,0.985 -1.664,2.17 -2.197,3.555c-0.533,1.384 -0.799,2.875 -0.799,4.473c0,1.598 0.293,3.089 0.879,4.474c0.586,1.385 1.385,2.57 2.397,3.555c1.011,0.985 2.196,1.757 3.554,2.317c1.359,0.559 2.81,0.838 4.354,0.838c1.811,0 3.462,-0.386 4.953,-1.158c1.158,-0.599 2.187,-1.367 3.088,-2.303c0.138,-0.147 0.175,-0.361 0.096,-0.546c-0.08,-0.184 -0.262,-0.304 -0.463,-0.304c-0.675,-0.003 -1.542,-0.003 -2.264,-0.003c-0.376,0 -0.736,-0.149 -1.001,-0.414c-0.265,-0.265 -0.414,-0.625 -0.414,-1c-0,-2.22 -0,-6.483 -0,-8.566c-0,-0.319 0.127,-0.625 0.352,-0.851c0.226,-0.226 0.532,-0.353 0.852,-0.353c3.626,0 14.421,0 18.09,0c0.683,0 1.237,0.554 1.237,1.237c-0,3.517 -0,13.478 -0,15.942c-0,0.371 -0.098,0.735 -0.285,1.055c-1.166,1.987 -2.535,3.805 -4.109,5.453c-1.704,1.784 -3.608,3.315 -5.712,4.593c-2.104,1.278 -4.381,2.264 -6.83,2.956c-2.45,0.692 -4.98,1.039 -7.59,1.039c-3.834,-0 -7.456,-0.719 -10.864,-2.157c-3.409,-1.438 -6.378,-3.409 -8.908,-5.912c-2.529,-2.503 -4.527,-5.459 -5.991,-8.867c-1.465,-3.409 -2.197,-7.084 -2.197,-11.025c-0,-3.834 0.692,-7.456 2.077,-10.864c1.385,-3.409 3.289,-6.378 5.712,-8.908c2.423,-2.53 5.299,-4.527 8.628,-5.991c3.328,-1.465 6.91,-2.197 10.744,-2.197c2.983,-0 5.566,0.293 7.749,0.879c2.184,0.585 4.021,1.251 5.513,1.997c1.18,0.572 2.229,1.181 3.145,1.825c0.707,0.512 0.872,1.497 0.369,2.212c-1.83,2.611 -6.063,8.628 -7.905,11.245Z"
          style="fill-rule:nonzero;" />
        <path
          d="M505.61,477.661c0,-3.834 0.733,-7.456 2.197,-10.864c1.465,-3.409 3.462,-6.378 5.992,-8.908c2.53,-2.53 5.499,-4.527 8.907,-5.991c3.409,-1.465 7.03,-2.197 10.865,-2.197c3.834,-0 7.456,0.732 10.864,2.197c3.409,1.464 6.378,3.461 8.908,5.991c2.53,2.53 4.527,5.499 5.991,8.908c1.465,3.408 2.197,7.03 2.197,10.864c0,3.835 -0.732,7.456 -2.197,10.865c-1.464,3.408 -3.461,6.377 -5.991,8.907c-2.53,2.53 -5.499,4.527 -8.908,5.992c-3.408,1.464 -7.03,2.197 -10.864,2.197c-3.835,-0 -7.456,-0.733 -10.865,-2.197c-3.408,-1.465 -6.377,-3.462 -8.907,-5.992c-2.53,-2.53 -4.527,-5.499 -5.992,-8.907c-1.464,-3.409 -2.197,-7.03 -2.197,-10.865Zm16.777,0c-0,1.545 0.293,2.996 0.878,4.354c0.586,1.358 1.385,2.543 2.397,3.555c1.012,1.012 2.197,1.811 3.555,2.397c1.358,0.585 2.809,0.878 4.354,0.878c1.544,0 2.996,-0.293 4.354,-0.878c1.358,-0.586 2.543,-1.385 3.555,-2.397c1.012,-1.012 1.81,-2.197 2.396,-3.555c0.586,-1.358 0.879,-2.809 0.879,-4.354c0,-1.544 -0.293,-2.996 -0.879,-4.354c-0.586,-1.358 -1.384,-2.543 -2.396,-3.555c-1.012,-1.011 -2.197,-1.81 -3.555,-2.396c-1.358,-0.586 -2.81,-0.879 -4.354,-0.879c-1.545,0 -2.996,0.293 -4.354,0.879c-1.358,0.586 -2.543,1.385 -3.555,2.396c-1.012,1.012 -1.811,2.197 -2.397,3.555c-0.585,1.358 -0.878,2.81 -0.878,4.354Z"
          style="fill-rule:nonzero;" />
      </g>
    </g>
  </svg>
</template>
