<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { Form } from 'vee-validate';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '~/Components/ui/form';
import Input from '~/Components/ui/input/Input.vue';
import PageHeading from '~/Components/v8/PageHeading.vue';
import { Card, CardContent, CardHeader, CardTitle } from '~/Components/ui/card';
import { OrganizationsAccountsShow } from '~/types/serializers';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '~/Components/ui/select';
import { Button } from '~/Components/ui/button';
import { useModuleApiStore } from '~/stores/moduleApi';
import { organizationsBankAccounts } from '~/api';
import CardFooter from '~/Components/ui/card/CardFooter.vue';
import { computed, unref } from 'vue';
import { back, getKeyByValue } from '~/lib/utils';
import { bankAccountSchema, IBankType } from './components/schema';
import CenterWrap from '~/Components/Wrapper/CenterWrap.vue';

const props = defineProps<{
  account: OrganizationsAccountsShow,
  types: IBankType
}>()

const schema = toTypedSchema(bankAccountSchema)

const initialValues = unref(props.account)
const isModeEdit = computed(() => initialValues.id)

if (initialValues && initialValues.id && initialValues.type) {
  initialValues.type = <any>getKeyByValue(props.types, initialValues.type)
}

const moduleApi = useModuleApiStore();
moduleApi.setEdit(organizationsBankAccounts.edit)
moduleApi.setCreate(organizationsBankAccounts.create)
moduleApi.setUpdate(organizationsBankAccounts.update)

const onSubmit = (values: any) => {
  if (initialValues.id) {
    moduleApi.gotoUpdate({ id: initialValues.id, ...values }, initialValues.id)
  } else {
    moduleApi.gotoCreate({ account: values })
  }
}
</script>

<template>
  <CenterWrap>
    <PageHeading :pageId="isModeEdit ? 'edit_bank_account' : 'add_bank_account'" class="mt-8" backButton />

    <Form :validation-schema="schema" :initial-values="initialValues" as="form" @submit="onSubmit">
      <Card>
        <CardHeader>
          <CardTitle>{{ $t('bank_account_info') }}</CardTitle>
        </CardHeader>

        <CardContent class="gap-6">
          <div class="max-w-lg space-y-5">
            <FormField v-slot="{ componentField }" name="name">
              <FormItem>
                <FormLabel>{{ $t('name') }}</FormLabel>
                <FormControl>
                  <Input type="text" placeholder="Personal bank" v-bind="componentField" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>

            <FormField v-slot="{ componentField }" name="code">
              <FormItem>
                <FormLabel>{{ $t('code') }}</FormLabel>
                <FormControl>
                  <Input type="text" placeholder="1-837480-11-2" v-bind="componentField" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>

            <FormField v-slot="{ componentField }" name="type">
              <FormItem>
                <FormLabel>{{ $t('bank_type') }}</FormLabel>
                <Select v-bind="componentField">
                  <SelectTrigger class="w-[180px]">
                    <SelectValue placeholder="Select a type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem v-for="(type, key) in types" :value="key" :key="type">
                        {{ type }}
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormItem>
            </FormField>

            <FormField v-slot="{ componentField }" name="bankName">
              <FormItem>
                <FormLabel>{{ $t('bank_name') }}</FormLabel>
                <FormControl>
                  <Input type="text" placeholder="Citibank" v-bind="componentField" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>

            <FormField v-slot="{ componentField }" name="accountNumber">
              <FormItem>
                <FormLabel>{{ $t('account_number') }}</FormLabel>
                <FormControl>
                  <Input type="text" placeholder="1-837480-11-2" v-bind="componentField" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
          </div>
        </CardContent>

        <CardFooter class="justify-end space-x-2">
          <Button type="button" @click="back" variant="outline">{{ $t('cancel') }}</Button>
          <Button type="submit">{{ $t('save_changes') }}</Button>
        </CardFooter>
      </Card>
    </Form>
  </CenterWrap>
</template>
