<script setup lang="ts">
import { each, get, isNil, merge, startCase } from 'lodash';
import { FieldEntry } from 'vee-validate';
import { FormControl, FormField, FormItem } from '~/Components/ui/form';
import Input from '~/Components/ui/input/Input.vue';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '~/Components/ui/table';
import { t } from '~/entrypoints/i18n';

const props = defineProps<{
  fields: FieldEntry<unknown>[]
}>()

type TColumnHeader = {
  accessorKey: string,
  header?: string,
  class?: string,
  key?: number
}

const columns: TColumnHeader[] = [
  {
    accessorKey: 'name',
    header: t('item_name'),
    class: "w-28"
  },
  {
    accessorKey: 'description'
  },
  {
    accessorKey: 'account',
  },
  {
    accessorKey: 'quantity',
  },
  {
    accessorKey: 'price',
  },
  {
    accessorKey: 'discountAmount',
  },
  {
    accessorKey: 'amount',
  },
  {
    accessorKey: 'taxId',
    header: t('tax')
  }
]

const columnHeaders = computed(() => {
  let results: typeof columns = []

  each(columns, (col) => {
    if (!get(props.fields[0].value, col.accessorKey)) return

    results.push(
      isNil(col.header) ?
      merge(col, {
        header: startCase(t(col.accessorKey))
      }) : col
    )
  })

  return results
})

</script>

<template>
  <div class="border rounded-md">
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead v-for="columnHeader in columnHeaders" :class="columnHeader.class" :key="columnHeader.accessorKey">
            {{ columnHeader.header }}
          </TableHead>
        </TableRow>
      </TableHeader>

      <TableBody>
        <template v-if="fields.length">
          <TableRow v-for="(field, index) in fields" :key="`items-${field.key}`">
            <template v-for="(column, key) in columns" :key="`cell-${key}`">
              <TableCell v-if="get(field.value, column.accessorKey)">
                <FormField v-slot="{ componentField }" :name="`items[${index}].${column.accessorKey}`">
                  <FormItem>
                    <FormControl>
                      <Input type="text" v-bind="componentField" />
                    </FormControl>
                  </FormItem>
                </FormField>
              </TableCell>
            </template>
          </TableRow>
        </template>
        <TableRow v-else>
          <TableCell :colspan="fields.length" class="h-24 text-center">
            {{ $t('table.no_result') }}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </div>
</template>
