<script setup lang="ts">
import PagyMetadata from '~/types/Metadata';
import DataTable from './components/DataTable.vue'
import { columns } from './components/columns'
import DialogForm from './components/DialogForm.vue';
import PageHeading from '~/Components/v8/PageHeading.vue';
import { OrganizationsAccountsIndex, OrganizationsAccountsShow } from '~/types/serializers';
import { usePage } from '@inertiajs/vue3';
import { organizationsAccounts } from '~/api';
import { useModuleApiStore } from '~/stores/moduleApi';
import TwoThirdWrap from '~/Components/Wrapper/TwoThirdWrap.vue';
import DetailsCard from './components/DetailsCard.vue';
import { ref } from 'vue';

defineProps<{ accounts: OrganizationsAccountsIndex[], metadata: PagyMetadata }>()
const dialogOpen = ref(false)
const openDialog = () => dialogOpen.value = true
const moduleApi = useModuleApiStore()
moduleApi.setVersion(usePage().version)
moduleApi.setNew(organizationsAccounts.new, { sideAction: openDialog, accept: 'json', expectedKey: 'account' })
moduleApi.setCreate(organizationsAccounts.create)
moduleApi.setEdit(organizationsAccounts.edit, { sideAction: openDialog, accept: 'json', expectedKey: 'account' })
moduleApi.setUpdate(organizationsAccounts.update)
moduleApi.setShow(organizationsAccounts.show, { accept: 'json' })
moduleApi.setDestroy(organizationsAccounts.destroy)

const clicked = async (row: OrganizationsAccountsShow) => {
  const response = await moduleApi.gotoShow(row.id)
  const { account } = response.props
  moduleApi.activeRecord = account as OrganizationsAccountsShow
}
</script>

<template>
  <div class="flex-col flex-1 h-full p-8 space-y-8 md:flex">
    <PageHeading pageId="accounts" />
  </div>

  <TwoThirdWrap>
    <DataTable :data="accounts" :columns="columns" @activeRow="clicked" />

    <template #last>
      <DetailsCard :account="moduleApi.activeRecord" v-if="moduleApi.activeRecord" />
    </template>
    <DialogForm :dialogOpen="dialogOpen" @toggle="(open: boolean) => dialogOpen = open" />
  </TwoThirdWrap>
</template>
