<script setup lang="ts">
import PageHeading from '~/Components/v8/PageHeading.vue';
import DataTable from './components/DataTable.vue';
import PagyMetadata from '~/types/Metadata';
import { columns } from './components/columns';
import { usePage } from '@inertiajs/vue3';
import { organizationsDeliveryOrders } from '~/api';
import { OrganizationsDeliveryOrdersIndex } from '~/types/serializers';

defineProps<{ delivery_orders: OrganizationsDeliveryOrdersIndex[], metadata: PagyMetadata }>()
const moduleApi = useModuleApiStore()
moduleApi.setVersion(usePage().version)
moduleApi.setNew(organizationsDeliveryOrders.new)
moduleApi.setShow(organizationsDeliveryOrders.show)
moduleApi.setEdit(organizationsDeliveryOrders.edit)
moduleApi.setDestroy(organizationsDeliveryOrders.destroy)

provide('action-show', true)
</script>

<template>
  <div class="flex-col flex-1 h-full p-8 space-y-8 md:flex">
    <PageHeading pageId="delivery_orders" />
    <DataTable :data="delivery_orders" :columns="columns" />
  </div>
</template>
