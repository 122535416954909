// JsFromRoutes CacheKey 41da53fe7cbdbed428ae95ef4ae0b8eb
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  void: /* #__PURE__ */ definePathHelper('patch', '/organization/credit_notes/:id/void'),
  approve: /* #__PURE__ */ definePathHelper('patch', '/organization/credit_notes/:id/approve'),
  apply: /* #__PURE__ */ definePathHelper('patch', '/organization/credit_notes/:id/apply'),
  index: /* #__PURE__ */ definePathHelper('get', '/organization/credit_notes'),
  create: /* #__PURE__ */ definePathHelper('post', '/organization/credit_notes'),
  new: /* #__PURE__ */ definePathHelper('get', '/organization/credit_notes/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/organization/credit_notes/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/organization/credit_notes/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/organization/credit_notes/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/organization/credit_notes/:id'),
}
