<script setup lang="ts">
import { Button } from '~/Components/ui/button'
import _pick from 'lodash/pick'
import _snakeCase from 'lodash/snakeCase'
import {
  MoreVertical,
} from 'lucide-vue-next'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '~/Components/ui/card'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '~/Components/ui/dropdown-menu'
import {
  Pagination,
  PaginationList,
  PaginationNext,
  PaginationPrev,
} from '~/Components/ui/pagination'
import { V8Icon } from '~/Components/icon'
import { OrganizationsAccountsShow } from '~/types/serializers'
import { t } from '~/entrypoints/i18n'
import { useModuleApiStore } from '~/stores/moduleApi'

defineProps<{
  account: OrganizationsAccountsShow
}>()
const handleClickEdit = (rowId: string | number) => useModuleApiStore().gotoEdit(rowId.toString())
// const handleClickDelete = (rowId: string) => useModuleApiStore().gotoDelete(rowId)
</script>

<template>
  <Card class="overflow-hidden">
    <CardHeader class="flex flex-row items-start bg-muted/50">
      <div class="grid gap-0.5">
        <CardTitle class="flex items-center gap-2 text-lg group">
          {{ account.name }}
        </CardTitle>
      </div>
      <div class="flex items-center gap-1 ml-auto">
        <Button size="sm" variant="outline" class="h-8 gap-1" @click="() => handleClickEdit(account.id)">
          <V8Icon icon="ic:twotone-edit" class="w-5 h-5" />
        </Button>
        <DropdownMenu>
          <DropdownMenuTrigger as-child>
            <Button size="icon" variant="outline" class="w-8 h-8">
              <MoreVertical class="h-3.5 w-3.5" />
              <span class="sr-only">More</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>Trash</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </CardHeader>
    <CardContent class="p-6 text-sm">
      <div class="grid gap-3">
        <ul class="grid gap-3">
          <li class="flex items-center justify-between" v-for="(val, key) in _pick(account, [
            'code',
            'name',
            'type',
            'description'
            ])">
            <span class="text-muted-foreground">
              {{ t(_snakeCase(key)) }}
            </span>
            <span>{{ val }}</span>
          </li>
        </ul>
      </div>
    </CardContent>
    <CardFooter class="flex flex-row items-center px-6 py-3 border-t bg-muted/50">
      <!-- <div class="text-xs text-muted-foreground">
        Updated <time dateTime="2023-11-23">November 23, 2023</time>
      </div> -->
      <Pagination class="w-auto ml-auto mr-0">
        <PaginationList class="gap-1">
          <PaginationPrev variant="outline" class="w-6 h-6" />
          <PaginationNext variant="outline" class="w-6 h-6" />
        </PaginationList>
      </Pagination>
    </CardFooter>
  </Card>
</template>
